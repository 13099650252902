import React from 'react'

import { ArrowLeft } from '@jsluna/icons'

const NavPrev = () => (
  <div className="ln-c-date-picker__nav-prev">
    <ArrowLeft aria-hidden />
  </div>
)

NavPrev.displayName = 'NavPrev'

export default NavPrev
