import React from 'react'
import PropTypes from 'prop-types'

import { filterProps } from '@jsluna/utils'

import TextInput from './TextInput'
import InputGroup from './InputGroup'
import InputIcon from './InputIcon'
import InputControl from './InputControl'
import InputAction from './InputAction'
import InputButton from './InputButton'
import FormGroup from './FormGroup'
import propTypes from './utils/fieldPropTypes'

/**
 * @see Combines [`TextInput`](#/Components/Inputs?id=textinput) and [`FormGroup`](#/Components/Form?id=formgroup-1) for a more feature rich component.
 * Also builds in functionality offered by the `InputGroup`(#/Components?id=inputgroup-1) components
 */
const TextInputField = ({
  action,
  actionFirst,
  button,
  className,
  element,
  error,
  hideLabel,
  icon,
  info,
  label,
  labelElement,
  name,
  optional,
  required,
  validationFirst,
  warning,
  ...props
}) => (
  <FormGroup
    className={className}
    element={element}
    error={error}
    hideLabel={hideLabel}
    info={info}
    label={label}
    labelElement={labelElement}
    name={name}
    optional={optional}
    required={required}
    validationFirst={validationFirst}
    warning={warning}
  >
    {inputProps => (
      <InputGroup>
        {icon && <InputIcon>{icon}</InputIcon>}
        {action && actionFirst && <InputAction>{action}</InputAction>}
        <InputControl>
          <TextInput
            {...inputProps}
            {...filterProps(props, [
              'setValue',
              'clearValue',
              'setRef',
              'hasAction',
              'onClear',
            ])}
          />
        </InputControl>
        {action && !actionFirst && <InputAction>{action}</InputAction>}
        {button && <InputButton>{button}</InputButton>}
      </InputGroup>
    )}
  </FormGroup>
)

TextInputField.propTypes = {
  ...propTypes,
  icon: PropTypes.node,
  action: PropTypes.node,
  button: PropTypes.node,
  actionFirst: PropTypes.bool,
}

TextInputField.defaultProps = {
  icon: undefined,
  action: undefined,
  button: undefined,
  actionFirst: false,
}

TextInputField.displayName = 'TextInputField'

export default TextInputField
