import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ArrowRight as Chevron } from 'luna-icons'

const colours = {
  success: 'green',
  info: 'blue',
  warning: 'amber',
  danger: 'red',
  none: 'transparent',
}

const StatusCard = ({
  className,
  link,
  onClick,
  status,
  children,
  element,
  containerElement,
  ...rest
}) => {
  const cardEl = containerElement || element

  const props = {
    ...rest,
    className: classnames('ln-c-status-card', className, {
      'ln-c-status-card--link': link || onClick,
      [`ln-c-status-card--${colours[status]}`]: colours[status],
    }),
    onClick,
  }

  const body = (
    <div>
      <div className="ln-c-status-card__content">{children}</div>
      {(link || onClick) && (
        <div className="ln-c-status-card__chevron">
          <Chevron />
        </div>
      )}
    </div>
  )

  return React.isValidElement(cardEl)
    ? React.cloneElement(cardEl, props, body)
    : React.createElement(onClick ? 'button' : cardEl, props, body)
}

StatusCard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['success', 'info', 'warning', 'danger', 'none']),
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** @deprecated Use `element` */
  containerElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

StatusCard.defaultProps = {
  className: undefined,
  link: false,
  onClick: undefined,
  status: undefined,
  element: 'div',
  containerElement: undefined,
}

StatusCard.displayName = 'StatusCard'

export default StatusCard
