/* eslint-disable import/no-cycle */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import NavigationDropdown from './NavigationDropdown'

const Navigation = ({ items, className, type, ...rest }) => (
  <ul
    {...rest}
    className={classnames(
      className.list,
      type &&
        (typeof type === 'string'
          ? `${className.list}--${type}`
          : type.map(modifier => `${className.list}--${modifier}`)),
    )}
  >
    {items.map((Element, index) => {
      const key =
        Element.props.href || Element.props.to || Element.props.label || index

      if (
        Element.type === 'li' ||
        Element.type.displayName === NavigationDropdown.displayName
      ) {
        return React.cloneElement(Element, {
          key,
        })
      }

      return (
        <li key={key} className={className.item}>
          {React.cloneElement(Element, {
            className: classnames(Element.props.className, className.link),
          })}
        </li>
      )
    })}
  </ul>
)

Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.shape({
    list: PropTypes.string,
    item: PropTypes.string,
    link: PropTypes.string,
  }),
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

Navigation.defaultProps = {
  items: [],
  className: {
    list: 'ln-c-navigation',
    item: 'ln-c-navigation__item',
    link: 'ln-c-navigation__link',
  },
  type: undefined,
}

Navigation.displayName = 'NavigationList'

export default Navigation
