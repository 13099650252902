import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const GridWrapper = ({
  className,
  children,
  matrix,
  reverse,
  equalHeight,
  gutterSize,
  verticalAlign,
  horizontalAlign,
  element,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames(className, 'ln-o-grid', {
        'ln-o-grid--matrix': matrix,
        'ln-o-grid--reverse': reverse,
        'ln-o-grid--equal-height': equalHeight,
        [`ln-o-grid--gutter-${gutterSize}`]: gutterSize,
        [`ln-o-grid--${verticalAlign}`]: verticalAlign,
        [`ln-u-text-align-${horizontalAlign}`]: horizontalAlign,
      })}
    >
      {children}
    </Element>
  )
}

GridWrapper.displayName = 'GridWrapper'

GridWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  matrix: PropTypes.bool,
  gutterSize: PropTypes.string,
  reverse: PropTypes.bool,
  equalHeight: PropTypes.bool,
  verticalAlign: PropTypes.oneOf(['middle', 'center', 'bottom']),
  horizontalAlign: PropTypes.oneOf(['center', 'right']),
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

GridWrapper.defaultProps = {
  className: undefined,
  matrix: false,
  gutterSize: undefined,
  reverse: false,
  equalHeight: false,
  verticalAlign: undefined,
  horizontalAlign: undefined,
  element: 'div',
}

export default GridWrapper
