import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Text = ({ element, children, className, typeStyle, fixed, ...rest }) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        typeStyle && `ln-u-${typeStyle}${fixed ? '-fixed' : ''}`,
        className,
      )}
    >
      {children}
    </Element>
  )
}

Text.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  typeStyle: PropTypes.string,
  fixed: PropTypes.bool,
}

Text.defaultProps = {
  element: 'span',
  children: undefined,
  className: undefined,
  typeStyle: undefined,
  fixed: false,
}

Text.displayName = 'Text'

export default Text
