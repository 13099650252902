export default (visible, handler) => {
  const className = 'has-overlay'

  if (!handler && typeof document === 'undefined') {
    return false
  }

  const classHandler = handler || document.body.classList

  if (visible) {
    return classHandler.add(className)
  }

  return classHandler.remove(className)
}
