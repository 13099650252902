import { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import getUniqueId from './getUniqueId'

class Portal extends Component {
  constructor(props) {
    super(props)

    const { exclusive, selector } = props

    this.state = {
      mounted: false,
    }

    if (typeof document !== 'undefined' && document.createElement) {
      this.parentEl = selector
        ? document.querySelector(selector) || document.body
        : document.body

      this.el = document.createElement('div')

      if (exclusive) {
        this.id = props.id || getUniqueId('portal-')
        this.el.id = this.id
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Using Portal without document present. Children will not be rendered.',
      )
    }
  }

  componentDidMount() {
    const { prepend, exclusive } = this.props

    if (
      typeof document === 'undefined' ||
      (exclusive && document.getElementById(this.id))
    ) {
      this.el = null
      return
    }

    if (this.parentEl) {
      if (prepend) {
        this.parentEl.insertBefore(this.el, this.parentEl.firstChild)
      } else {
        this.parentEl.appendChild(this.el)
      }

      this.setState({ mounted: true })
    }
  }

  componentWillUnmount() {
    if (document && this.parentEl && this.el) {
      this.parentEl.removeChild(this.el)
    }
  }

  render() {
    const { children } = this.props
    const { mounted } = this.state

    return mounted && this.el && createPortal(children, this.el)
  }
}

Portal.propTypes = {
  children: PropTypes.node,
  prepend: PropTypes.bool,
  exclusive: PropTypes.bool,
  id: PropTypes.string,
  selector: PropTypes.string,
}

Portal.defaultProps = {
  children: undefined,
  prepend: false,
  exclusive: false,
  id: undefined,
  selector: undefined,
}

Portal.displayName = 'Portal'

export default Portal
