import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from './Button'

const IconButton = ({ children, className, hideLabel, label, ...rest }) => (
  <Button
    {...rest}
    className={classnames(
      hideLabel ? 'ln-c-button--icon' : 'ln-c-button--icon-text',
      className,
    )}
  >
    {React.cloneElement(React.Children.only(children), {
      className: 'ln-c-button__icon',
    })}
    {hideLabel ? <span className="ln-u-visually-hidden">{label}</span> : label}
  </Button>
)

IconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** Text content to show along side icon to give greater context */
  label: PropTypes.node.isRequired,
  /** Whether the text should only be available to screen readers */
  hideLabel: PropTypes.bool,
}

IconButton.defaultProps = {
  children: undefined,
  className: undefined,
  hideLabel: false,
}

IconButton.displayName = 'IconButton'

export default IconButton
