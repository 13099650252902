/* eslint-disable import/no-cycle */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { compose, withState, withHandlers, setDisplayName } from 'recompose'

import { ClickAway } from '../utilities'
import filterProps from '../../utils/filterProps'

import Navigation from './Navigation'

const NavigationDropdown = ({
  toggleOpen,
  isOpen,
  label,
  items,
  setOpen,
  Element,
  ...rest
}) => (
  <li
    className="ln-c-navigation__item ln-c-navigation__item--has-dropdown"
    key={`${label}-dropdown`}
    {...filterProps(rest, ['activeClassName'])}
  >
    <ClickAway onClickAway={() => setOpen(false)}>
      {React.cloneElement(
        Element,
        {
          className: classNames(
            'ln-c-navigation__link ln-c-navigation__dropdown-toggle',
            isOpen && 'is-open',
            Element.props.className,
          ),
          onClick: e => {
            e.stopPropagation()
            toggleOpen()
          },
        },
        <Fragment>
          {label}
          <span className="ln-c-navigation__dropdown-icon" />
        </Fragment>,
      )}
    </ClickAway>
    <div
      className={classNames('ln-c-navigation__dropdown', isOpen && 'is-open')}
    >
      <Navigation items={items} />
    </div>
  </li>
)

NavigationDropdown.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** @deprecated will change to `element` in next major version */
  Element: PropTypes.node,
}

NavigationDropdown.defaultProps = {
  Element: <button type="button" />,
}

NavigationDropdown.displayName = 'NavigationDropdown'

export { NavigationDropdown as NavigationDropdownComponent }
export default compose(
  setDisplayName(NavigationDropdown.displayName),
  withState('isOpen', 'setOpen', false),
  withHandlers({
    toggleOpen: ({ isOpen, setOpen }) => () => setOpen(!isOpen),
  }),
)(NavigationDropdown)
