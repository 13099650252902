import React from 'react'

import { ArrowRight } from '@jsluna/icons'

const NavNext = () => (
  <div className="ln-c-date-picker__nav-next">
    <ArrowRight aria-hidden />
  </div>
)

NavNext.displayName = 'NavNext'

export default NavNext
