import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const LabelInfo = ({ className, children, ...rest }) => (
  <span {...rest} className={classnames('ln-c-label__info', className)}>
    {children}
  </span>
)

LabelInfo.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

LabelInfo.defaultProps = {
  className: undefined,
}

LabelInfo.displayName = 'LabelInfo'

export default LabelInfo
