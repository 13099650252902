import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ButtonGroupSecondary = ({ element, children, className, ...rest }) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames(className, 'ln-c-button-group__secondary')}
    >
      {children}
    </Element>
  )
}

ButtonGroupSecondary.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ButtonGroupSecondary.defaultProps = {
  element: 'div',
  className: undefined,
}

ButtonGroupSecondary.displayName = 'ButtonGroupSecondary'

export default ButtonGroupSecondary
