import React from 'react'
import PropTypes from 'prop-types'

import { Navigation } from '../navigation'
import { Search } from '../search'

const navigationClasses = {
  list: 'ln-c-icon-nav',
  item: 'ln-c-icon-nav__item',
  link: 'ln-c-icon-nav__link',
}

const searchClasses = {
  form: 'ln-c-icon-nav__form',
  input: 'ln-c-icon-nav__text-input',
}

const IconNavigation = ({ onSearchSubmit, items, ...rest }) => (
  <Navigation.List
    {...rest}
    className={navigationClasses}
    items={
      onSearchSubmit
        ? [
            <li
              key="search"
              className="ln-c-icon-nav__item ln-c-icon-nav__item--fixed"
            >
              <Search
                className={searchClasses}
                onSubmit={onSearchSubmit}
                expandable
              />
            </li>,
            ...items,
          ]
        : items
    }
  />
)

IconNavigation.propTypes = {
  onSearchSubmit: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.element),
}

IconNavigation.defaultProps = {
  onSearchSubmit: undefined,
  items: [],
}

IconNavigation.displayName = 'IconNavigation'

export default IconNavigation
