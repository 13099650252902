const sortAlphabetically = (accessor, sortValue, ascending) => (a, b) => {
  const valueA = accessor(a)[sortValue]
  const valueB = accessor(b)[sortValue]

  if (valueA < valueB) {
    return ascending ? -1 : 1
  }
  if (valueA > valueB) {
    return ascending ? 1 : -1
  }
  return 0
}

const sort = ({ data, sorted }) => {
  if (Object.keys(sorted).length > 0) {
    const sortFunction =
      typeof sorted.sortValue === 'function'
        ? sorted.sortValue(sorted.accessor, sorted.ascending)
        : sortAlphabetically(
            sorted.accessor,
            sorted.sortValue,
            sorted.ascending,
          )

    return {
      data: data.sort(sortFunction),
    }
  }
  return {}
}

const sortDataHandler = ({ setSorted }) => sortOptions => setSorted(sortOptions)

export { sort, sortDataHandler }
