import React from 'react'
import {
  compose,
  withState,
  withHandlers,
  withProps,
  setDisplayName,
  withPropsOnChange,
} from 'recompose'

import { CancelButton } from '../button'

export const onChangeHandler = ({ onChange, setValue }) => e => {
  if (onChange) {
    onChange(e)
  }
  setValue(e.target.value)
}

export const getClearActionProps = (value, clearValue) =>
  value.length > 0
    ? {
        hasAction: true,
        action: (
          <CancelButton onClick={clearValue} aria-label="Clear the field" />
        ),
      }
    : { hasAction: false, action: undefined }

export const setFieldGroupElement = ({ setRef }) => ({
  // eslint-disable-next-line react/prop-types, react/display-name
  element: ({ children, className }) => (
    <div ref={setRef} className={className}>
      {children}
    </div>
  ),
})

const withClear = WrappedField => {
  /* istanbul ignore next */
  const WithClear = compose(
    setDisplayName('WithClear'),
    withState(
      'value',
      'setValue',
      ({ defaultValue, value = '' }) => defaultValue || value,
    ),
    withHandlers(({ onClear }) => {
      let input

      return {
        onChange: onChangeHandler,
        clearValue: ({ setValue }) => () => {
          setValue('')

          if (input) {
            input.focus()
          }

          if (onClear) {
            onClear()
          }
        },
        setRef: () => field => {
          if (field) {
            input = field.querySelector('input')
          }
        },
      }
    }),
    withProps(({ value, clearValue }) => ({
      ...getClearActionProps(value, clearValue),
    })),
    withPropsOnChange(() => false, setFieldGroupElement),
  )(WrappedField)

  return WithClear
}

/** @component */
export default withClear
