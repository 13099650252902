import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableBody = ({ children, className, ...rest }) => (
  <tbody {...rest} className={classnames(className, 'ln-c-table__body')}>
    {children}
  </tbody>
)

TableBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
}

TableBody.defaultProps = {
  children: undefined,
  className: undefined,
}

TableBody.displayName = 'TableBody'

export default TableBody
