import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableRow = ({ children, className, ...rest }) => (
  <tr {...rest} className={classnames(className, 'ln-c-table__row')}>
    {children}
  </tr>
)

TableRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

TableRow.defaultProps = {
  children: undefined,
  className: undefined,
}

TableRow.displayName = 'TableRow'

export default TableRow
