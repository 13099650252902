import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ListGroup = ({
  element,
  children,
  className,
  actionable,
  type,
  color,
  inline,
  size,
  spaced,
  hard,
  open,
  onDropdownBlur,
  onItemClick,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-list-group',
        actionable && 'ln-c-list-group--actionable',
        type && `ln-c-list-group--${type}`,
        color && `ln-c-list-group--${color}`,
        size && `ln-c-list-group--${size}`,
        inline &&
          (typeof inline === 'string'
            ? `ln-c-list-group--inline@${inline}`
            : 'ln-c-list-group--inline'),
        spaced && 'ln-c-list-group--spaced',
        hard && 'ln-c-list-group--hard',
        className,
      )}
      aria-hidden={!open || undefined}
    >
      {open &&
        React.Children.map(children, (child, index) => {
          const props = {}
          if (onDropdownBlur && React.Children.count(children) === index + 1) {
            props.onBlur = onDropdownBlur
          }

          if (onItemClick) {
            props.onClick = onItemClick
          }

          if (Object.keys(props).length) {
            return React.cloneElement(child, props)
          }

          return child
        })}
    </Element>
  )
}

ListGroup.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Items within the group should have hover states */
  actionable: PropTypes.bool,
  /** Change the list item style to menu or pill navigation variants */
  type: PropTypes.oneOf(['full', 'pill']),
  /** Change colour theme used */
  color: PropTypes.oneOf(['light', 'dark']),
  /** Display items in a row, can provide a breakpoint reference for responsive behaviour */
  inline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Change the height of items to use `$ln-size-sm` or `$ln-size-lg` */
  size: PropTypes.oneOf(['lg', 'sm']),
  /** Adds margins between ListGroupItems */
  spaced: PropTypes.bool,
  /** Turns off padding from child ListGroupItems */
  hard: PropTypes.bool,
  /** Whether the List contents should be rendered to the page */
  open: PropTypes.bool,
  /** Event that fires on focus out of the last item of a dropdown list, can be used to close the dropdown */
  onDropdownBlur: PropTypes.func,
  /** Common onClick handler that will be applied to any nested items */
  onItemClick: PropTypes.func,
}

ListGroup.defaultProps = {
  /** Allows the top-level element to be customized */
  element: 'ul',
  children: undefined,
  className: undefined,
  actionable: false,
  type: undefined,
  color: undefined,
  inline: false,
  size: undefined,
  spaced: false,
  hard: false,
  open: true,
  onDropdownBlur: undefined,
  onItemClick: undefined,
}

ListGroup.displayName = 'ListGroup'

export default ListGroup
