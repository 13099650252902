import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Container } from '../container'

const toSize = (large, squash) => {
  if (large) {
    return 'lg'
  }
  if (squash) {
    // Note: "squash" is intentionally mapped to default size
    // And is in effect deprecated.
  }
  return undefined
}

/**
 * @deprecated Use [`Container`](#/Components/Container?id=container-1)
 */
const SiteWrap = ({ padded, className, children, large, squash, ...rest }) => (
  <Container
    {...rest}
    soft={padded}
    size={toSize(large, squash)}
    className={classnames(className)}
    clearfix
  >
    {children}
  </Container>
)

SiteWrap.propTypes = {
  padded: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  large: PropTypes.bool,
  squash: PropTypes.bool,
}

SiteWrap.defaultProps = {
  padded: false,
  large: false,
  squash: false,
  className: undefined,
}

SiteWrap.displayName = 'SiteWrap'

export default SiteWrap
