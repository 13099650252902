import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from './Button'
import { VARIANT } from './constants'

/**
 * @see Extends [`Button`](#/Components/Button?id=button-1) by setting relevant `variant`
 */
const OutlinedButton = props => (
  <Button
    {...props}
    variant={VARIANT.OUTLINED}
    className={classnames('ln-c-button--outlined', props.className)}
  >
    {props.children}
  </Button>
)

OutlinedButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

OutlinedButton.defaultProps = {
  className: undefined,
}

OutlinedButton.displayName = 'OutlinedButton'

export default OutlinedButton
