import React from 'react'
import PropTypes from 'prop-types'

import { Header } from '../header'
import MainSection from './MainSection'

/**
 * @deprecated Use [`SiteLayout`](#/Components/SiteLayout)
 */
const ColleagueLayout = ({
  element,
  children,
  className,
  mainId,
  open,
  toggle,
  topBar,
  logo,
  iconNav,
  menuItems,
  onSearchSubmit,
  container,
  ...rest
}) => {
  const headerProps = {
    mainId,
    open,
    toggle,
    topBar,
    logo,
    iconNav,
    menuItems,
    onSearchSubmit,
    container,
  }
  const Element = element
  return (
    <Element {...rest} className={className}>
      <Header {...headerProps} />
      <MainSection className="ln-u-soft-top">{children}</MainSection>
    </Element>
  )
}

ColleagueLayout.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...Header.propTypes,
}

ColleagueLayout.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
}

ColleagueLayout.displayName = 'ColleagueLayout'

export default ColleagueLayout
