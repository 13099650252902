import React from 'react'

import Select from './Select'
import FormGroup from './FormGroup'
import propTypes from './utils/fieldPropTypes'

const SelectField = ({
  className,
  error,
  hideLabel,
  info,
  label,
  labelElement,
  name,
  optional,
  required,
  validationFirst,
  warning,
  ...props
}) => (
  <FormGroup
    className={className}
    error={error}
    hideLabel={hideLabel}
    info={info}
    label={label}
    labelElement={labelElement}
    name={name}
    optional={optional}
    required={required}
    validationFirst={validationFirst}
    warning={warning}
  >
    {inputProps => <Select {...inputProps} {...props} />}
  </FormGroup>
)

SelectField.propTypes = propTypes

SelectField.displayName = 'SelectField'

export default SelectField
