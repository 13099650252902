import React from 'react'
import PropTypes from 'prop-types'

const PageHeader = ({ element, children }) => {
  const Element = element

  return <Element className="ln-o-page__header">{children}</Element>
}

PageHeader.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
}

PageHeader.defaultProps = {
  element: 'div',
  children: undefined,
}

PageHeader.displayName = 'PageHeader'

export default PageHeader
