import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableHeader = ({ children, sortLabel, className, ...rest }) => (
  <thead
    {...rest}
    className={classnames(className, 'ln-c-table__header')}
    aria-label={sortLabel ? 'Sort by' : undefined}
  >
    {children}
  </thead>
)

TableHeader.propTypes = {
  children: PropTypes.node,
  sortLabel: PropTypes.bool,
  className: PropTypes.string,
}

TableHeader.defaultProps = {
  children: undefined,
  sortLabel: false,
  className: undefined,
}

TableHeader.displayName = 'TableHeader'

export default TableHeader
