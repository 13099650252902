import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { VARIANT } from './constants'

/**
 * @see Extends [`Button`](#/Components/Button?id=button-1) by adding setting relevant `variant`
 */
const TextButton = ({ children, ...props }) => (
  <Button {...props} variant={VARIANT.TEXT}>
    {children}
  </Button>
)

TextButton.propTypes = {
  children: PropTypes.node.isRequired,
}

TextButton.displayName = 'TextButton'

export default TextButton
