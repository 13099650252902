import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Article = ({
  element,
  children,
  className,
  label,
  labelledby,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(className, 'ln-o-article')}
      aria-label={label}
      aria-labelledby={labelledby}
    >
      {children}
    </Element>
  )
}

Article.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Short description of the article to allow screen readers to use the component as a region */
  label: PropTypes.string,
  /** ID of element with text content describing the article to allow screen readers to use the component as a region */
  labelledby: PropTypes.string,
}

Article.defaultProps = {
  element: 'article',
  children: undefined,
  className: undefined,
  label: undefined,
  labelledby: undefined,
}

Article.displayName = 'Article'

export default Article
