import { compose, withProps, setDisplayName } from 'recompose'
import Heading from './Heading'

export default level =>
  compose(
    setDisplayName(`Heading${level}`),
    withProps({
      level,
    }),
  )(Heading)
