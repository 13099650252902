import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CloseButton } from '../button'
import { ListItem } from '../list'

const NotificationItem = ({
  children,
  className,
  notification,
  handleClose,
  open,
  ...rest
}) => (
  <ListItem
    {...rest}
    type="bare"
    className={classnames(className, 'ln-c-activities__item', {
      'ln-c-activities__item--notification': notification,
      'is-open': notification && open,
    })}
  >
    {notification && handleClose && <CloseButton onClick={handleClose} />}
    {children}
  </ListItem>
)

NotificationItem.displayName = 'NotificationItem'

NotificationItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  notification: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
}

NotificationItem.defaultProps = {
  className: undefined,
  notification: false,
  open: false,
  handleClose: undefined,
}

export default NotificationItem
