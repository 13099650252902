import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Label from './Label'
import FieldInfo from './FieldInfo'
import getRequiredFlag from './getRequiredFlag'
import getDescribedByIds from './getDescribedByIds'

const getAriaDescribedBy = props => {
  const describedBy = getDescribedByIds(props)
  return describedBy.length > 0 ? describedBy.join(' ') : undefined
}

/**
  `FormGroup` can be used to create labelled, accessible form fields.
  It is used internally by various `Fields` within Luna e.g. `TextInputField`,
  but can also be used to create you own custom form elements.
*/
const FormGroup = ({
  element,
  labelElement,
  name,
  required,
  optional,
  label,
  error,
  warning,
  info,
  children,
  className,
  validationFirst,
  hideLabel,
  ...rest
}) => {
  const Element = element

  const hasError = !!error
  const hasWarning = !!warning

  return (
    <Element
      {...rest}
      className={classnames(className, 'ln-c-form-group', {
        'has-error': hasError,
        'has-warning': hasWarning,
      })}
    >
      {!!label && (
        <Label
          element={labelElement}
          htmlFor={name}
          requiredLabel={required}
          optionalLabel={optional}
          hidden={hideLabel}
        >
          {label}
        </Label>
      )}
      {!!info && <FieldInfo id={`${name}Info`}>{info}</FieldInfo>}
      {(hasError || hasWarning) && (
        <FieldInfo
          id={`${name}Validation`}
          displayFirst={validationFirst}
          error={hasError}
          warning={hasWarning}
        >
          {error || warning}
        </FieldInfo>
      )}
      {typeof children === 'function'
        ? children({
            name,
            required: getRequiredFlag(required, optional),
            'aria-describedby': getAriaDescribedBy({
              name,
              info,
              warning,
              error,
            }),
            invalid: error !== undefined ? !!error : undefined,
          })
        : children}
    </Element>
  )
}

FormGroup.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  labelElement: Label.propTypes.element,
  name: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  optional: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  label: PropTypes.node,
  hideLabel: PropTypes.bool,
  error: PropTypes.node,
  warning: PropTypes.node,
  info: PropTypes.node,
  /**
   If a function is passed it will be used as a "render prop"
   with `name`, `required`, `invalid` and `aria-describedby` passed
   down on render. This can then be spread onto the underlying input.
  */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  validationFirst: PropTypes.bool,
}

FormGroup.defaultProps = {
  element: 'div',
  labelElement: undefined,
  required: undefined,
  optional: undefined,
  label: undefined,
  hideLabel: false,
  info: undefined,
  warning: undefined,
  error: undefined,
  className: undefined,
  validationFirst: true,
}

FormGroup.displayName = 'FormGroup'

export default FormGroup
