import React from 'react'
import PropTypes from 'prop-types'

import {
  ArrowFirst as IconFirst,
  ArrowLast as IconLast,
  ArrowLeft as IconPrevious,
  ArrowRight as IconNext,
} from 'luna-icons'

import PaginationItem from './PaginationItem'

import {
  ALL_TYPES,
  TYPE_PREVIOUS,
  TYPE_NEXT,
  TYPE_FIRST,
  TYPE_LAST,
  LINK_REL,
} from './constants'

const TYPES = {
  [TYPE_PREVIOUS]: {
    label: 'Previous',
    icon: IconPrevious,
    rel: LINK_REL[TYPE_PREVIOUS],
  },
  [TYPE_NEXT]: {
    label: 'Next',
    icon: IconNext,
    rel: LINK_REL[TYPE_NEXT],
  },
  [TYPE_FIRST]: {
    label: 'First',
    icon: IconFirst,
    rel: LINK_REL[TYPE_FIRST],
  },
  [TYPE_LAST]: {
    label: 'Last',
    icon: IconLast,
    rel: LINK_REL[TYPE_LAST],
  },
}

const buildLabel = type => `${TYPES[type].label} page`

const PaginationControl = props => {
  const typeProps = TYPES[props.type]
  const Icon = typeProps.icon
  return (
    <PaginationItem
      {...props}
      type={props.type}
      label={buildLabel(props.type)}
      rel={typeProps.rel}
    >
      <Icon aria-hidden />
    </PaginationItem>
  )
}

PaginationControl.displayName = 'PaginationControl'

PaginationControl.propTypes = {
  type: PropTypes.oneOf(ALL_TYPES).isRequired,
}

export const PaginationPrevious = props => (
  <PaginationControl {...props} type={TYPE_PREVIOUS} />
)
PaginationPrevious.displayName = 'PaginationPrevious'

export const PaginationNext = props => (
  <PaginationControl {...props} type={TYPE_NEXT} />
)
PaginationNext.displayName = 'PaginationNext'

export const PaginationFirst = props => (
  <PaginationControl {...props} type={TYPE_FIRST} />
)
PaginationFirst.displayName = 'PaginationFirst'

export const PaginationLast = props => (
  <PaginationControl {...props} type={TYPE_LAST} />
)
PaginationLast.displayName = 'PaginationLast'
