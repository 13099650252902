import FlagWrapper from './FlagWrapper'
import FlagBody from './FlagBody'
import FlagComponent from './FlagComponent'

// Deprecated
const Flag = {
  Wrapper: FlagWrapper,
  Body: FlagBody,
  Component: FlagComponent,
}

export { Flag, FlagWrapper, FlagBody, FlagComponent }
