import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableCaption = ({ children, className, visuallyHidden, ...rest }) => (
  <caption
    {...rest}
    className={classnames(
      'ln-c-table__caption',
      className,
      visuallyHidden && 'ln-u-visually-hidden',
    )}
  >
    {children}
  </caption>
)

TableCaption.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  visuallyHidden: PropTypes.bool,
}

TableCaption.defaultProps = {
  className: undefined,
  visuallyHidden: false,
}

TableCaption.displayName = 'TableCaption'

export default TableCaption
