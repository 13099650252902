import React from 'react'
import PropTypes from 'prop-types'

import PaginationNav from './PaginationNav'
import PaginationInfo from './PaginationInfo'
import {
  PaginationFirst,
  PaginationLast,
  PaginationPrevious,
  PaginationNext,
} from './PaginationControl'

import PaginationPages from './PaginationPages'

const Pagination = ({
  className,
  current,
  total,
  onChange,
  linkElement,
  createHref,
  showFirstAndLast,
  showPages,
  pageRange,
  fullWidth,
}) => (
  <PaginationNav
    className={className}
    showFirstAndLast={showFirstAndLast}
    showPages={showPages}
    fullWidth={fullWidth}
  >
    {showFirstAndLast && (
      <PaginationFirst
        disabled={current <= 1}
        page={1}
        element={linkElement}
        onClick={onChange}
        href={createHref(1)}
      />
    )}
    <PaginationPrevious
      disabled={current <= 1}
      page={current - 1}
      element={linkElement}
      onClick={onChange}
      href={createHref(current - 1)}
    />
    <PaginationInfo current={current} total={total} />
    {showPages && (
      <PaginationPages
        range={pageRange}
        current={current}
        total={total}
        element={linkElement}
        onChange={onChange}
        createHref={createHref}
      />
    )}
    <PaginationNext
      disabled={current >= total}
      page={current + 1}
      element={linkElement}
      onClick={onChange}
      href={createHref(current + 1)}
    />
    {showFirstAndLast && (
      <PaginationLast
        disabled={current >= total}
        page={total}
        element={linkElement}
        onClick={onChange}
        href={createHref(total)}
      />
    )}
  </PaginationNav>
)

Pagination.propTypes = {
  /** Custom CSS classes */
  className: PropTypes.string,
  /** Currently selected page */
  current: PropTypes.number.isRequired,
  /** Total number of pages */
  total: PropTypes.number.isRequired,
  /* Called when current page number changes */
  onChange: PropTypes.func.isRequired,
  /** Element to be used as link */
  linkElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ]),
  /** Function used to generate page link `href`s (page number passed as argument) */
  createHref: PropTypes.func,
  /** Show first and last page links i.e. `|<` and `>|` */
  showFirstAndLast: PropTypes.bool,
  /** Show page number buttons/links */
  showPages: PropTypes.bool,
  /** Number of page buttons/links displayed each side of the current page */
  pageRange: PropTypes.number,
  /** Resize to 100% width of the parent */
  fullWidth: PropTypes.bool,
}

Pagination.defaultProps = {
  className: undefined,
  linkElement: 'a',
  createHref: page => `#${page}`,
  showFirstAndLast: false,
  showPages: false,
  pageRange: 1,
  fullWidth: false,
}

Pagination.displayName = 'Pagination'

export default Pagination
