import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Navigation } from '../navigation'
import { Container } from '../container'

const navigationClasses = {
  list: 'ln-c-topbar__list',
  item: 'ln-c-topbar__item',
  link: 'ln-o-bare-link ln-c-topbar__text',
}

const TopBar = ({
  element,
  logo,
  items,
  meta,
  className,
  toggle,
  hasDivider,
  container,
  ...rest
}) => {
  const Element = element

  const createOnClickHandle = onClick => e => {
    toggle(false)

    if (onClick) {
      onClick(e)
    }
  }

  const addOnClickHandler = menuItem =>
    cloneElement(menuItem, {
      onClick: createOnClickHandle(menuItem.props.onClick),
    })

  return (
    <Element
      {...rest}
      className={classnames(
        className,
        'ln-c-topbar',
        !hasDivider && 'ln-c-topbar--hide-divider',
      )}
    >
      <Container {...container} clearfix>
        <div className="ln-o-flag">
          <div className="ln-o-flag__body">
            <Navigation.List
              className={navigationClasses}
              items={
                logo
                  ? [
                      <li
                        key="logo"
                        className="ln-c-topbar__item ln-c-topbar__item--logo"
                      >
                        {logo}
                      </li>,
                      ...items.map(addOnClickHandler),
                    ]
                  : items.map(addOnClickHandler)
              }
            />
          </div>
          {meta && (
            <div className="ln-o-flag__component">
              <div className="ln-c-topbar__text">{meta}</div>
            </div>
          )}
        </div>
      </Container>
    </Element>
  )
}

TopBar.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  logo: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.node),
  meta: PropTypes.node,
  className: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  hasDivider: PropTypes.bool,
  container: PropTypes.shape(Container.propTypes),
}

TopBar.defaultProps = {
  element: 'div',
  logo: undefined,
  items: [],
  meta: undefined,
  className: undefined,
  hasDivider: true,
  container: undefined,
}

TopBar.displayName = 'TopBar'

export default TopBar
