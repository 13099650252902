import React from 'react'
import PropTypes from 'prop-types'

import { FormOption } from '../inputs'
import { List, ListItem } from '../list'

import FormGroup from './FormGroup'
import propTypes from './fieldPropTypes'

const getChecked = ({ optionGroupValue, optionValue, optionChecked }) => {
  let checked
  if (optionGroupValue !== undefined) checked = optionValue === optionGroupValue
  if (optionChecked !== undefined) checked = optionChecked
  return checked
}

const FormOptionFieldInput = ({ option, optionGroupValue, ...props }) => (
  <FormOption
    {...props}
    {...option}
    checked={getChecked({
      optionGroupValue,
      optionValue: option.value,
      optionChecked: option.checked,
    })}
  />
)

FormOptionFieldInput.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.string,
    checked: PropTypes.bool,
  }).isRequired,
  optionGroupValue: PropTypes.string,
}

FormOptionFieldInput.defaultProps = {
  optionGroupValue: undefined,
}

FormOptionFieldInput.displayName = 'FormOptionFieldInput'

const FormOptionField = ({
  className,
  name,
  required,
  requiredLabel,
  optional,
  optionalLabel,
  validationFirst,
  label,
  hideLabel,
  error,
  warning,
  info,
  options,
  optionType,
  listType,
  outlined,
  ...props
}) => {
  const optionGroupValue =
    props.value !== undefined ? props.value.toString() : undefined

  const optionInputProps = {
    ...props,
    type: optionType,
  }

  return (
    <FormGroup
      element="fieldset"
      labelElement="legend"
      name={name}
      required={required}
      optional={optional}
      label={label}
      hideLabel={hideLabel}
      error={error}
      warning={warning}
      info={info}
      className={className}
      validationFirst={validationFirst}
    >
      {inputProps =>
        options.length > 1 ? (
          <List type={listType} spaced={outlined && 'sm'}>
            {options.map(option => (
              <ListItem type={listType} key={`${name}-${option.value}`}>
                <FormOptionFieldInput
                  {...inputProps}
                  {...optionInputProps}
                  option={option}
                  optionGroupValue={optionGroupValue}
                  innerRef={option.innerRef}
                  outlined={outlined}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <FormOptionFieldInput
            {...inputProps}
            {...optionInputProps}
            option={options[0]}
            optionGroupValue={optionGroupValue}
            innerRef={options[0].innerRef}
          />
        )
      }
    </FormGroup>
  )
}

FormOptionField.propTypes = {
  ...propTypes,
  /** The list of options to be made available */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** What format the options should take */
  optionType: PropTypes.oneOf(['radio', 'checkbox', 'switch']),
  /** How the group of options should be displayed, e.g. inline */
  listType: PropTypes.string,
  /** Whether the option should have a border */
  outlined: PropTypes.bool,
}

FormOptionField.defaultProps = {
  optionType: 'radio',
  listType: 'bare',
  outlined: false,
}

FormOptionField.displayName = 'FormOptionField'

export default FormOptionField
