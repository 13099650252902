import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ListGroupItem = ({
  element,
  containerElement,
  children,
  className,
  active,
  activeType,
  ...rest
}) => {
  const ContainerElement = containerElement
  const Element = element

  return (
    <ContainerElement>
      <Element
        {...rest}
        className={classnames(
          'ln-c-list-group__item',
          active && 'is-active',
          className,
        )}
        aria-current={active ? activeType : undefined}
      >
        {children}
      </Element>
    </ContainerElement>
  )
}

ListGroupItem.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /** Element that will wrap the item */
  containerElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Should the item be highlighted within the list */
  active: PropTypes.bool,
  /** Additional context for screen readers - what does the active item relate to */
  activeType: PropTypes.string,
}

ListGroupItem.defaultProps = {
  element: 'span',
  containerElement: 'li',
  children: undefined,
  className: undefined,
  active: false,
  activeType: 'page',
}

ListGroupItem.displayName = 'ListGroupItem'

export default ListGroupItem
