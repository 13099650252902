import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableHeaderRow = ({ children, className, ...rest }) => (
  <tr
    {...rest}
    className={classnames(
      className,
      'ln-c-table__row',
      'ln-c-table__header-row',
    )}
  >
    {children}
  </tr>
)

TableHeaderRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

TableHeaderRow.defaultProps = {
  children: undefined,
  className: undefined,
}

TableHeaderRow.displayName = 'TableHeaderRow'

export default TableHeaderRow
