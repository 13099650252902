import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ListItem = ({ className, children, type, ...rest }) => (
  <li
    {...rest}
    className={classnames(className, {
      [`ln-o-${type}-list__item`]: type,
    })}
  >
    {children}
  </li>
)

ListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['bare', 'inline', 'matrix', 'justified']),
}

ListItem.defaultProps = {
  className: undefined,
  type: undefined,
}

ListItem.displayName = 'ListItem'

export default ListItem
