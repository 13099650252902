import React from 'react'
import PropTypes from 'prop-types'

import {
  NumberInput,
  InputGroup,
  InputIcon,
  InputControl,
  InputAction,
  InputButton,
} from '../inputs'

import filterProps from '../../utils/filterProps'

import FormGroup from './FormGroup'
import propTypes from './fieldPropTypes'

/**
 * @see Combines [`NumberInput`](#/Components/Inputs?id=textinput) and [`FormGroup`](#/Components/Form?id=formgroup-1) for a more feature rich component.
 * Also builds in functionality offered by the `InputGroup`(#/Components?id=inputgroup-1) components
 */
const NumberInputField = ({
  className,
  required,
  requiredLabel,
  optional,
  optionalLabel,
  validationFirst,
  label,
  hideLabel,
  error,
  warning,
  info,
  labelElement,
  button,
  icon,
  action,
  actionFirst,
  element,
  name,
  ...props
}) => (
  <FormGroup
    name={name}
    required={required}
    optional={optional}
    label={label}
    hideLabel={hideLabel}
    error={error}
    warning={warning}
    info={info}
    className={className}
    validationFirst={validationFirst}
    labelElement={labelElement}
    element={element}
  >
    {inputProps => (
      <InputGroup>
        {icon && <InputIcon>{icon}</InputIcon>}
        {action && actionFirst && <InputAction>{action}</InputAction>}
        <InputControl>
          <NumberInput
            {...inputProps}
            {...filterProps(props, [
              'setValue',
              'clearValue',
              'setRef',
              'hasAction',
              'onClear',
            ])}
          />
        </InputControl>
        {action && !actionFirst && <InputAction>{action}</InputAction>}
        {button && <InputButton>{button}</InputButton>}
      </InputGroup>
    )}
  </FormGroup>
)

NumberInputField.propTypes = {
  ...propTypes,
  icon: PropTypes.node,
  action: PropTypes.node,
  button: PropTypes.node,
  actionFirst: PropTypes.bool,
}

NumberInputField.defaultProps = {
  icon: undefined,
  action: undefined,
  button: undefined,
  actionFirst: false,
}

NumberInputField.displayName = 'NumberInputField'

export default NumberInputField
