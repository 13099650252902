import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Heading } from '../typography'

const ModalHeading = ({ className, ...props }) => (
  <Heading
    {...props}
    className={classnames('ln-c-modal__heading', className)}
  />
)

ModalHeading.displayName = 'ModalHeading'

ModalHeading.propTypes = {
  className: PropTypes.string,
}

ModalHeading.defaultProps = {
  className: undefined,
}

export default ModalHeading
