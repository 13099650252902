import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const HiddenInput = ({ multiSelect, name, value }) => {
  if (multiSelect) {
    return (
      <Fragment>
        {value.length > 0 ? (
          value.map(optionValue => (
            <input
              key={optionValue}
              name={`${name}[]`}
              type="hidden"
              value={optionValue}
            />
          ))
        ) : (
          <input name={`${name}[]`} type="hidden" value="" />
        )}
      </Fragment>
    )
  }

  return <input name={name} type="hidden" value={value} />
}

HiddenInput.propTypes = {
  multiSelect: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

HiddenInput.defaultProps = {
  multiSelect: false,
  value: '',
}

HiddenInput.displayName = 'HiddenInput'

export default HiddenInput
