import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { getComponentName } from '@jsluna/utils'

const InputGroup = ({
  element,
  children,
  className,
  hasIcon,
  hasAction,
  hasButton,
  disabled,
  ...rest
}) => {
  const Element = element
  let hasIconClass = hasIcon
  let hasActionClass = hasAction
  let hasButtonClass = hasButton

  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      const componentName = getComponentName(child)
      if (componentName === 'InputIcon') {
        hasIconClass = true
      }

      if (componentName === 'InputAction') {
        hasActionClass = true
      }

      if (componentName === 'InputButton') {
        hasButtonClass = true
      }
    }
  })

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-input-group',
        hasIconClass && 'ln-c-input-group--has-icon',
        hasActionClass && 'ln-c-input-group--has-action',
        hasButtonClass && 'ln-c-input-group--has-button',
        disabled && 'is-disabled',
        className,
      )}
    >
      {children}
    </Element>
  )
}

InputGroup.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
  hasAction: PropTypes.bool,
  hasButton: PropTypes.bool,
  /** Applies disabled styling to elements within the group  */
  disabled: PropTypes.bool,
}

InputGroup.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  hasIcon: false,
  hasAction: false,
  hasButton: false,
  disabled: false,
}

InputGroup.displayName = 'InputGroup'

export default InputGroup
