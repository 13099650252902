import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Link = ({ element, children, className, bare, cta, color, ...rest }) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-link',
        bare && 'ln-c-link--bare',
        cta && 'ln-c-link--cta',
        color && `ln-c-link--${color}`,
        className,
      )}
    >
      {children}
    </Element>
  )
}

Link.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  bare: PropTypes.bool,
  cta: PropTypes.bool,
  color: PropTypes.string,
}

Link.defaultProps = {
  element: 'a',
  children: undefined,
  className: undefined,
  bare: false,
  cta: false,
  color: undefined,
}

Link.displayName = 'Link'

export default Link
