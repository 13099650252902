import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const MenuButton = ({ className, onClick, light, dark, ...rest }) => (
  <button
    {...rest}
    type="button"
    className={className}
    aria-hidden="true"
    tabIndex="-1"
    onClick={onClick}
  >
    <span
      className={classnames(
        'ln-navigation-toggle',
        light && 'ln-navigation-toggle--light',
        dark && 'ln-navigation-toggle--dark',
      )}
    >
      <span className="ln-c-navigation-toggle__item" />
    </span>
  </button>
)

MenuButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  light: PropTypes.bool,
  dark: PropTypes.bool,
}

MenuButton.defaultProps = {
  className: undefined,
  light: false,
  dark: false,
}

MenuButton.displayName = 'MenuButton'

export default MenuButton
