import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TabList from './TabList'
import getAnimateDistance from './getAnimateDistance'
import getElementDimensions from './getElementDimensions'

const ARROW_WIDTH = 48
const VISIBLE_TABS = 2

class Tabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      animateDistance: 0,
      selectedIndex: 0,
      numberOfTabs: 0,
      remainingTabs: 0,
    }

    this.handleArrowClick = this.handleArrowClick.bind(this)
    this.handleTabLinkFocus = this.handleTabLinkFocus.bind(this)
    this.isResponsiveEnabled = this.isResponsiveEnabled.bind(this)
    this.setSelectedTab = this.setSelectedTab.bind(this)
    this.getNumberOfTabs = this.getNumberOfTabs.bind(this)
    this.getNumberOfRemainingTabs = this.getNumberOfRemainingTabs.bind(this)
  }

  getNumberOfTabs(children) {
    const { numberOfTabs } = this.state
    const tabs = React.Children.count(children)

    if (tabs !== numberOfTabs) {
      this.setState({ numberOfTabs: tabs }, () => {
        this.setState({
          remainingTabs: this.getNumberOfRemainingTabs(),
        })
      })
    }
  }

  getNumberOfRemainingTabs() {
    const { numberOfTabs, selectedIndex } = this.state

    return numberOfTabs - (selectedIndex + 1) * VISIBLE_TABS
  }

  setSelectedTab(selectedIndex) {
    this.setState({ selectedIndex }, () => {
      const remainingTabs = this.getNumberOfRemainingTabs()

      this.setState({
        remainingTabs,
        animateDistance: getAnimateDistance(
          selectedIndex,
          remainingTabs,
          VISIBLE_TABS,
        ),
      })
    })
  }

  isResponsiveEnabled() {
    const { numberOfTabs } = this.state
    const { fill } = this.props

    return !fill && numberOfTabs > VISIBLE_TABS
  }

  handleArrowClick(next) {
    const modifier = next ? 1 : -1

    this.setSelectedTab(this.state.selectedIndex + modifier)
  }

  handleTabLinkFocus(e) {
    if (this.isResponsiveEnabled()) {
      const { x: wrapperX, width: wrapperWidth } = getElementDimensions(
        this.wrapper,
      )
      const { x: focussedX, width: focussedWidth } = getElementDimensions(
        e.target,
      )

      if (focussedX + focussedWidth >= wrapperX + wrapperWidth) {
        this.setSelectedTab(this.state.selectedIndex + 1)
      } else if (focussedX + focussedWidth <= wrapperX + ARROW_WIDTH) {
        this.setSelectedTab(this.state.selectedIndex - 1)
      }
    }
  }

  render() {
    const Element = this.props.element
    const { selectedIndex, remainingTabs, animateDistance } = this.state
    let responsiveProps = {}

    if (this.isResponsiveEnabled()) {
      responsiveProps = {
        showNextArrow: remainingTabs > 0,
        showPrevArrow: selectedIndex > 0,
        animateDistance,
      }
    }

    return (
      <Element
        ref={wrapper => {
          this.wrapper = wrapper
        }}
      >
        <TabList
          onArrowClick={this.handleArrowClick}
          onTabLinkFocus={this.handleTabLinkFocus}
          getChildren={this.getNumberOfTabs}
          {...responsiveProps}
          {...this.props}
        />
      </Element>
    )
  }
}

Tabs.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  fill: PropTypes.bool,
}

Tabs.defaultProps = {
  element: 'div',
  fill: false,
}

Tabs.displayName = 'Tabs'

export default Tabs
