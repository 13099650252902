import React, { Component } from 'react'
import PropTypes from 'prop-types'

import filterProps from '../../utils/filterProps'

import AutocompleteField from './AutocompleteField'
import { getOptionValue as getOptionValueDefault } from './getOptionProperties'

class MultiAutocompleteField extends Component {
  constructor(props) {
    const { defaultSelectedOptions } = props
    super(props)

    this.state = {
      inputValue: '',
      selectedOptions: defaultSelectedOptions,
    }

    this.handleClear = this.handleClear.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.filterSelectedOptions = this.filterSelectedOptions.bind(this)
  }

  handleClear(selectedOption) {
    const { selectedOptions } = this.state
    const { onSelect } = this.props

    const newSelectedOptions = selectedOptions.filter(
      option => option !== selectedOption,
    )

    this.setState({
      inputValue: '',
      selectedOptions: newSelectedOptions,
    })

    if (onSelect) {
      onSelect(newSelectedOptions)
    }
  }

  handleInputChange(e) {
    const { onChange } = this.props

    this.setState({
      inputValue: e.target.value,
    })

    if (onChange) {
      onChange(e)
    }
  }

  handleSelect(selectedOption) {
    const { selectedOptions } = this.state
    const { onSelect } = this.props

    const newSelectedOptions = [...selectedOptions, selectedOption]

    this.setState({
      inputValue: '',
      selectedOptions: newSelectedOptions,
    })

    if (onSelect) {
      onSelect(newSelectedOptions)
    }
  }

  filterSelectedOptions(option) {
    const { selectedOptions } = this.state
    const { getOptionValue } = this.props

    return !selectedOptions.find(
      selectedOption =>
        getOptionValue(selectedOption) === getOptionValue(option),
    )
  }

  render() {
    const { selectedOptions, inputValue } = this.state

    return (
      <AutocompleteField
        {...filterProps(this.props, ['defaultSelectedOptions'])}
        options={this.props.options.filter(this.filterSelectedOptions)}
        multiSelect
        value={inputValue}
        selectedOptions={selectedOptions}
        onClear={this.handleClear}
        onSelect={this.handleSelect}
        onChange={this.handleInputChange}
      />
    )
  }
}

MultiAutocompleteField.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultSelectedOptions: PropTypes.arrayOf(PropTypes.object),
  getOptionValue: PropTypes.func,
}

MultiAutocompleteField.defaultProps = {
  getOptionValue: getOptionValueDefault,
  onChange: undefined,
  onSelect: undefined,
  options: [],
  defaultSelectedOptions: [],
}

MultiAutocompleteField.displayName = 'MultiAutocompleteField'

export default MultiAutocompleteField
