import React from 'react'
import PropTypes from 'prop-types'

const Main = ({
  element,
  children,
  className,
  id,
  label,
  labelledby,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={className}
      id={id}
      role="main"
      aria-label={label}
      aria-labelledby={labelledby}
    >
      {children}
    </Element>
  )
}

Main.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** ID used by skip links for screen readers to jump to content */
  id: PropTypes.string,
  /** Short description of the content to give additional context to screen readers */
  label: PropTypes.string,
  /** ID of element containing text describing the content to give additional context to screen readers */
  labelledby: PropTypes.string,
}

Main.defaultProps = {
  element: 'main',
  children: undefined,
  className: undefined,
  id: 'main-content',
  label: undefined,
  labelledby: undefined,
}

Main.displayName = 'Main'

export default Main
