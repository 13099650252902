import React from 'react'

import { ArrowRight } from 'luna-icons'

const NavNext = () => (
  <div className="ln-c-date-picker__nav-next">
    <ArrowRight aria-hidden />
  </div>
)

NavNext.displayName = 'NavNext'

export default NavNext
