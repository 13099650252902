import { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

class Portal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mounted: false,
    }

    if (typeof document !== 'undefined' && document.createElement) {
      this.el = document.createElement('div')
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Using Portal without document present. Children will not be rendered.',
      )
    }
  }

  componentDidMount() {
    const { prepend } = this.props

    if (typeof document !== 'undefined' && document.body) {
      if (prepend) {
        document.body.prepend(this.el)
      } else {
        document.body.appendChild(this.el)
      }

      this.setState({ mounted: true })
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeChild(this.el)
    }
  }

  render() {
    const { children } = this.props
    const { mounted } = this.state

    return mounted && createPortal(children, this.el)
  }
}

Portal.propTypes = {
  children: PropTypes.node,
  prepend: PropTypes.bool,
}

Portal.defaultProps = {
  children: undefined,
  prepend: false,
}

Portal.displayName = 'Portal'

export default Portal
