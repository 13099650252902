export const POSITION_FIRST_CHILD = 'first-child'
export const POSITION_LAST_CHILD = 'last-child'
export const POSITION_ONLY_CHILD = 'only-child'

export const ALL_POSITIONS = [
  POSITION_FIRST_CHILD,
  POSITION_LAST_CHILD,
  POSITION_ONLY_CHILD,
]

export const TYPE_PREVIOUS = 'previous'
export const TYPE_NEXT = 'next'
export const TYPE_FIRST = 'first'
export const TYPE_LAST = 'last'
export const TYPE_PAGE = 'page'

export const ALL_TYPES = [
  TYPE_PREVIOUS,
  TYPE_NEXT,
  TYPE_FIRST,
  TYPE_LAST,
  TYPE_PAGE,
]

export const LINK_REL = {
  [TYPE_PREVIOUS]: 'prev',
  [TYPE_NEXT]: 'next',
  [TYPE_FIRST]: 'first',
  [TYPE_LAST]: 'last',
}

export const ELLIPSIS = '...'
