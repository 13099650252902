import React from 'react'
import PropTypes from 'prop-types'

const PageFooter = ({ element, children }) => {
  const Element = element

  return <Element className="ln-o-page__footer">{children}</Element>
}

PageFooter.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
}

PageFooter.defaultProps = {
  element: 'div',
  children: undefined,
}

PageFooter.displayName = 'PageFooter'

export default PageFooter
