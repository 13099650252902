export const VARIANT = {
  FILLED: 'filled',
  LINK: 'link',
  OUTLINED: 'outlined',
  TEXT: 'text',
  /** @deprecated */
  PRIMARY: 'primary',
  /** @deprecated */
  SECONDARY: 'secondary',
}

export const COLOR = {
  BETA: 'beta',
  DARK: 'dark',
  LIGHT: 'light',
}
