import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { List } from '../list'

const NotificationList = ({ children, className, open, ...rest }) => (
  <List
    {...rest}
    type="bare"
    className={classnames(className, 'ln-c-activities', {
      'is-open': open,
    })}
  >
    {children}
  </List>
)

NotificationList.displayName = 'NotificationList'

NotificationList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
}

NotificationList.defaultProps = {
  className: undefined,
  open: false,
}

export default NotificationList
