import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from './Button'
import { VARIANT } from './constants'

/**
 * @see Extends [`Button`](#/Components/Button?id=button-1) by setting relevant `variant`
 */
const LinkButton = ({ className, children, bare, ...props }) => (
  <Button
    {...props}
    variant={VARIANT.LINK}
    className={classnames(bare && 'ln-c-button--link-bare', className)}
  >
    <span className="ln-c-button__underline">{children}</span>
  </Button>
)

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Removes the default line decoration under LinkButton */
  bare: PropTypes.bool,
}

LinkButton.defaultProps = {
  className: undefined,
  bare: false,
}

LinkButton.displayName = 'LinkButton'

export default LinkButton
