import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Section = ({
  element,
  children,
  className,
  soft,
  label,
  labelledby,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-o-section',
        soft && `ln-o-section--${soft}`,
        className,
      )}
      aria-label={label}
      aria-labelledby={labelledby}
    >
      {children}
    </Element>
  )
}

Section.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Sets size of spacing below section based on `$ln-section-soft-variants` variable */
  soft: PropTypes.string,
  /** Short description of the section to allow screen readers to use the component as a region */
  label: PropTypes.string,
  /** ID of element with text content describing the section to allow screen readers to use the component as a region */
  labelledby: PropTypes.string,
}

Section.defaultProps = {
  element: 'section',
  children: undefined,
  className: undefined,
  soft: undefined,
  label: undefined,
  labelledby: undefined,
}

Section.displayName = 'Section'

export default Section
