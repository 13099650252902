import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CancelButton from './CancelButton'

/**
 * @see Extends [`CancelButton`](#/Components?id=cancelbutton) with preset style `className` &
 * `title` for use with dismissable components
 */
const CloseButton = ({ className, onClick, label, ...rest }) => (
  <CancelButton
    {...rest}
    className={classnames(className, 'ln-c-dismiss')}
    onClick={onClick}
    aria-label={label}
  />
)

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
}

CloseButton.defaultProps = {
  className: undefined,
  label: 'Close',
}

CloseButton.displayName = 'CloseButton'

export default CloseButton
