import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Container } from '../container'

const NavigationWrapper = ({
  children,
  title,
  titleElement,
  button,
  container,
  className,
  label,
  ...rest
}) => {
  const Title = titleElement

  return (
    <div {...rest} className={classnames(className, 'ln-c-navigation-wrapper')}>
      <Container
        {...container}
        className={classnames(container.className, 'ln-u-hard-right')}
        clearfix
      >
        {button}
        {!!title && (
          <Title className="ln-c-navigation-wrapper__title">{title}</Title>
        )}
        {!!children && (
          <nav className="ln-c-navigation-wrapper__inner" aria-label={label}>
            {children}
          </nav>
        )}
      </Container>
    </div>
  )
}

NavigationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  button: PropTypes.element,
  title: PropTypes.node,
  titleElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  container: PropTypes.shape(Container.propTypes),
  className: PropTypes.string,
  label: PropTypes.string,
}

NavigationWrapper.defaultProps = {
  button: undefined,
  title: undefined,
  titleElement: 'h1',
  container: Container.defaultProps,
  className: undefined,
  label: undefined,
}

NavigationWrapper.displayName = 'NavigationWrapper'

export default NavigationWrapper
