import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'

import FormGroup from './FormGroup'

import { getOptionLabel as getOptionLabelDefault } from './getOptionProperties'

const AutocompleteField = ({
  element,
  onSelect,
  defaultSelectedOption,
  ...props
}) => {
  const Element = element
  return (
    <Downshift
      itemToString={props.getOptionLabel}
      onChange={onSelect}
      inputValue={props.value}
      defaultSelectedItem={defaultSelectedOption}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        clearSelection,
      }) => (
        <Element>
          <FormGroup
            getInputProps={getInputProps}
            getItemProps={getItemProps}
            getLabelProps={getLabelProps}
            getMenuProps={getMenuProps}
            isOpen={isOpen}
            inputValue={inputValue}
            highlightedIndex={highlightedIndex}
            selectedItem={selectedItem}
            getToggleButtonProps={getToggleButtonProps}
            clearSelection={clearSelection}
            {...props}
          />
        </Element>
      )}
    </Downshift>
  )
}

AutocompleteField.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  getOptionLabel: PropTypes.func,
  value: PropTypes.string,
  onSelect: PropTypes.func,
  defaultSelectedOption: PropTypes.shape({}),
}

AutocompleteField.defaultProps = {
  element: 'div',
  getOptionLabel: getOptionLabelDefault,
  value: undefined,
  onSelect: undefined,
  defaultSelectedOption: undefined,
}

AutocompleteField.displayName = 'AutocompleteField'

export default AutocompleteField
