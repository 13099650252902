import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Input from './Input'

const TextInput = ({ className, ...props }) => (
  <Input className={classnames('ln-c-text-input', className)} {...props} />
)

TextInput.propTypes = {
  className: PropTypes.string,
}

TextInput.defaultProps = {
  className: undefined,
}

TextInput.displayName = 'TextInput'

export default TextInput
