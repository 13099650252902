import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { VARIANT } from './constants'

/**
 * @see Extends [`Button`](#/Components/Button?id=button-1) by setting relevant `variant`
 * @deprecated Use [`OutlinedButton`](#/Components/Button?id=outlinedbutton) or
 * [`TextButton`](#/Components/Button?id=textbutton) instead
 */
const SecondaryButton = ({ children, ...rest }) => (
  <Button {...rest} variant={VARIANT.SECONDARY}>
    {children}
  </Button>
)

SecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
}

SecondaryButton.displayName = 'SecondaryButton'

export default SecondaryButton
