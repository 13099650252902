import BreadcrumbsWrapper from './BreadcrumbsWrapper'
import BreadcrumbsItem from './BreadcrumbsItem'

/** @deprecated Use BreadcrumbsWrapper & BreadcrumbsItem */
const Breadcrumbs = {
  Wrapper: BreadcrumbsWrapper,
  Item: BreadcrumbsItem,
}

export { Breadcrumbs, BreadcrumbsWrapper, BreadcrumbsItem }
