import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TextArea = ({
  id,
  name,
  placeholder,
  disabled,
  onBlur,
  onChange,
  onFocus,
  value,
  defaultValue,
  minLength,
  maxLength,
  autoComplete,
  autoCapitalize,
  required,
  readOnly,
  spellCheck,
  tabIndex,
  className,
  invalid,
  cols,
  rows,
  wrap,
  innerRef,
  ...rest
}) => (
  <textarea
    {...rest}
    id={id || name}
    name={name}
    className={classnames(
      'ln-c-text-input ln-c-text-input--textarea',
      className,
    )}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    value={value}
    defaultValue={defaultValue}
    placeholder={placeholder}
    tabIndex={tabIndex}
    autoComplete={autoComplete}
    autoCapitalize={autoCapitalize}
    spellCheck={spellCheck}
    required={required}
    minLength={minLength}
    maxLength={maxLength}
    readOnly={readOnly}
    disabled={disabled}
    aria-disabled={disabled || undefined}
    aria-required={required === false ? required : undefined}
    aria-invalid={invalid || undefined}
    cols={cols}
    rows={rows}
    wrap={wrap}
    ref={innerRef}
  />
)

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.string,
  autoCapitalize: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  spellCheck: PropTypes.oneOf(['default', true, false]),
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  invalid: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  wrap: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

TextArea.defaultProps = {
  id: undefined,
  disabled: false,
  placeholder: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  value: undefined,
  defaultValue: undefined,
  checked: undefined,
  defaultChecked: undefined,
  minLength: undefined,
  maxLength: undefined,
  autoComplete: undefined,
  autoCapitalize: undefined,
  required: undefined,
  readOnly: false,
  spellCheck: undefined,
  tabIndex: undefined,
  className: undefined,
  invalid: undefined,
  rows: undefined,
  cols: undefined,
  wrap: undefined,
  innerRef: undefined,
}

TextArea.displayName = 'TextArea'

export default TextArea
