import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const InputAction = ({ children }) => {
  const element = React.Children.only(children)

  return React.cloneElement(element, {
    className: classnames(
      'ln-c-input-group__action',
      element.props && element.props.className,
    ),
  })
}

InputAction.propTypes = {
  children: PropTypes.node,
}

InputAction.defaultProps = {
  children: undefined,
}

InputAction.displayName = 'InputAction'

export default InputAction
