import React from 'react'
import PropTypes from 'prop-types'

import { SearchButton } from '@jsluna/button'
import { filterProps } from '@jsluna/utils'

import SearchInput from './SearchInput'
import FormGroup from './FormGroup'
import propTypes from './utils/fieldPropTypes'

const excludeProps = ['setValue', 'clearValue', 'setRef', 'onClear']

/**
 * @see Combines [`SearchInput`](#/Components/Inputs?id=searchinput) and [`FormGroup`](#/Components/Form?id=formgroup-1) for a more feature rich component.
 */
const SearchField = ({
  button,
  className,
  element,
  error,
  hideLabel,
  info,
  label,
  labelElement,
  name,
  optional,
  required,
  validationFirst,
  warning,
  ...props
}) => (
  <FormGroup
    className={className}
    element={element}
    error={error}
    hideLabel={hideLabel}
    info={info}
    label={label}
    labelElement={labelElement}
    name={name}
    optional={optional}
    required={required}
    validationFirst={validationFirst}
    warning={warning}
  >
    {inputProps => (
      <SearchInput
        {...inputProps}
        {...filterProps(props, excludeProps)}
        button={button}
      />
    )}
  </FormGroup>
)

SearchField.propTypes = {
  ...propTypes,
  button: PropTypes.node,
}

SearchField.defaultProps = {
  button: <SearchButton />,
}

SearchField.displayName = 'SearchField'

export default SearchField
