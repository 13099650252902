import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { arrayIncludes } from '@jsluna/utils'

import ListItem from './ListItem'

const List = ({
  className,
  children,
  type,
  spaced,
  items,
  ordered,
  ...rest
}) => {
  const ListElement = ordered ? 'ol' : 'ul'
  const keys = []

  return (
    <ListElement
      {...rest}
      className={classnames(
        className,
        type && `ln-o-${type}-list`,
        type &&
          spaced &&
          (typeof spaced === 'string'
            ? `ln-o-${type}-list--spaced-${spaced}`
            : `ln-o-${type}-list--spaced`),
      )}
    >
      {items
        ? items.map((item, index) => {
            const key = `listItem-${
              !arrayIncludes(keys, item.toString()) ? item : index
            }`
            keys.push(item.toString())

            return (
              <ListItem key={key} type={type}>
                {item}
              </ListItem>
            )
          })
        : React.Children.map(
            children,
            child =>
              child && child.type && child.type === ListItem
                ? React.cloneElement(child, { type })
                : child,
          )}
    </ListElement>
  )
}

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.node),
  type: PropTypes.oneOf(['bare', 'inline', 'matrix', 'justified']),
  spaced: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ordered: PropTypes.bool,
}

List.defaultProps = {
  className: undefined,
  children: false,
  items: undefined,
  type: undefined,
  spaced: false,
  ordered: false,
}

List.displayName = 'List'

export default List
