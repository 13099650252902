import React from 'react'
import PropTypes from 'prop-types'
import DRP from 'react-dates/lib/components/DateRangePicker'

import NavPrev from './NavPrev'
import NavNext from './NavNext'

import { WEEK_DAY_FORMAT } from './constants'

/**
 * @see Take a look at [Airbnb's documentation](https://github.com/airbnb/react-dates#daterangepicker) for information on how to customise and configure this component.
 */
const DateRangePicker = ({ element, showKeyboardShortcutsPanel, ...props }) => {
  const Element = element
  return (
    <Element className="ln-c-date-picker">
      <DRP
        navPrev={<NavPrev />}
        navNext={<NavNext />}
        weekDayFormat={WEEK_DAY_FORMAT}
        hideKeyboardShortcutsPanel={!showKeyboardShortcutsPanel}
        numberOfMonths={1}
        keepOpenOnDateSelect
        {...props}
      />
    </Element>
  )
}

DateRangePicker.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  showKeyboardShortcutsPanel: PropTypes.bool,
}

DateRangePicker.defaultProps = {
  element: 'div',
  showKeyboardShortcutsPanel: false,
}

DateRangePicker.displayName = 'DateRangePicker'

export default DateRangePicker
