import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ArrowLeft, ArrowRight } from 'luna-icons'

import filterProps from '../../utils/filterProps'

import TabLink from './TabLink'

const TabList = ({
  className,
  children,
  fill,
  deep,
  pill,
  light,
  dark,
  showPrevArrow,
  showNextArrow,
  animateDistance,
  getChildren,
  onArrowClick,
  onTabLinkFocus,
  ...rest
}) => {
  const isResponsive =
    !fill && React.Children.count(children) > 1 && onArrowClick
  const transform =
    isResponsive && animateDistance
      ? `translateX(${animateDistance}%)`
      : undefined

  if (getChildren) {
    getChildren(children)
  }

  return (
    <nav
      {...filterProps(rest, ['visibleTabs'])}
      className={classnames('ln-c-tabs', className, {
        'ln-c-tabs--fill': fill,
        'ln-c-tabs--pill': pill,
        'ln-c-tabs--light': light,
        'ln-c-tabs--dark': dark,
        'ln-c-tabs--deep': deep,
        'has-prev-arrow': showPrevArrow,
        'has-next-arrow': showNextArrow,
      })}
    >
      <div className="ln-c-tabs__list-wrap">
        <div className="ln-c-tabs__list" role="tablist" style={{ transform }}>
          {isResponsive
            ? React.Children.map(children, (child, index) => {
                /* eslint-disable react/prop-types */
                const props = {
                  key: index,
                }

                if (child.type === TabLink) {
                  props.onFocus = onTabLinkFocus
                }

                return React.cloneElement(child, props)
                /* eslint-enable react/prop-types */
              })
            : children}
        </div>
        {isResponsive && [
          <button
            type="button"
            key="arrowPrev"
            className="ln-c-tabs__link ln-c-tabs__link--arrow ln-c-tabs__link--arrow-prev"
            tabIndex="-1"
            onClick={() => onArrowClick(false)}
          >
            <ArrowLeft />
          </button>,
          <button
            type="button"
            key="arrowNext"
            className="ln-c-tabs__link ln-c-tabs__link--arrow ln-c-tabs__link--arrow-next"
            tabIndex="-1"
            onClick={() => onArrowClick(true)}
          >
            <ArrowRight />
          </button>,
        ]}
      </div>
    </nav>
  )
}

TabList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Take up full width of container with space split between links evenly */
  fill: PropTypes.bool,
  /** Use larger variant of TabLink */
  deep: PropTypes.bool,
  /** Use pill TabLink style */
  pill: PropTypes.bool,
  /** Use light colour theme variant */
  light: PropTypes.bool,
  /** Use dark colour theme variant */
  dark: PropTypes.bool,
  showPrevArrow: PropTypes.bool,
  showNextArrow: PropTypes.bool,
  animateDistance: PropTypes.number,
  getChildren: PropTypes.func,
  onArrowClick: PropTypes.func,
  onTabLinkFocus: PropTypes.func,
}

TabList.defaultProps = {
  className: undefined,
  fill: false,
  deep: false,
  pill: false,
  light: false,
  dark: false,
  showPrevArrow: false,
  showNextArrow: false,
  animateDistance: 0,
  getChildren: undefined,
  onArrowClick: undefined,
  onTabLinkFocus: undefined,
}

TabList.displayName = 'TabList'

export default TabList
