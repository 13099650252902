import {
  ELLIPSIS,
  POSITION_FIRST_CHILD,
  POSITION_LAST_CHILD,
  POSITION_ONLY_CHILD,
} from './constants'

const range = (from, to) => {
  const o = []
  for (let i = from; i <= to; i += 1) {
    o.push(i)
  }
  return o
}

export const calcPages = (current, total, plusOrMinus) => {
  const core = 1 + 2 * plusOrMinus
  const numOfSlots = core + 2 + 2 /* 2 ellipsis + (first + last) */

  // If it fits, I sits
  if (total <= numOfSlots) {
    return range(1, total)
  }

  // Pages
  const offset = plusOrMinus + 1 + 1
  const middle = Math.min(Math.max(current, 1 + offset), total - offset)
  const pages = range(middle - plusOrMinus, middle + plusOrMinus)

  // Beginning
  if (pages[0] !== 1) {
    pages.splice(0, 0, 1)
    pages.splice(1, 0, pages[1] === 3 ? 2 : ELLIPSIS)
  }

  // End
  if (pages[pages.length - 1] !== total) {
    pages.push(total)
    pages.splice(
      -1,
      0,
      pages[pages.length - 2] === total - 2 ? total - 1 : ELLIPSIS,
    )
  }

  return pages
}

export const getPages = (current, total, plusOrMinus) => {
  const pages = calcPages(current, total, plusOrMinus)
  const getPosition = (prev, next) => {
    const p = prev && prev !== ELLIPSIS
    const n = next && next !== ELLIPSIS
    if (!p && !n) return POSITION_ONLY_CHILD
    if (!p) return POSITION_FIRST_CHILD
    if (!n) return POSITION_LAST_CHILD
    return undefined
  }
  const o = []
  let prev
  let next
  for (let i = 0; i < pages.length; i += 1) {
    const page = pages[i]
    next = i < pages.length - 1 ? pages[i + 1] : undefined
    o.push({
      page,
      position: getPosition(prev, next),
    })
    prev = page
  }
  return o
}

// https://stackoverflow.com/a/31615643/186965
export const calcOrdinal = n => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
