import React from 'react'

const PaginationEllipsis = () => (
  <li
    className="ln-c-pagination__item ln-c-pagination__item--ellipsis"
    aria-hidden
  >
    &hellip;
  </li>
)

PaginationEllipsis.displayName = 'PaginationEllipsis'

export default PaginationEllipsis
