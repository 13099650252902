import React, { Component } from 'react'
import PropTypes from 'prop-types'

import debounce from '../../utils/debounce'
import filterProps from '../../utils/filterProps'
import { LoadingIndicator } from '../loadingIndicator'

import AutocompleteField from './AutocompleteField'

const LoadingMessage = () => <LoadingIndicator message="Loading..." />

LoadingMessage.displayName = 'LoadingMessage'

class AsyncAutocompleteField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [],
      loading: false,
      inputValue: '',
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.getNoResultsMessage = this.getNoResultsMessage.bind(this)
  }

  getOptions(value) {
    const { loadOptions } = this.props

    this.setState({
      loading: true,
    })

    loadOptions(value).then(options => {
      this.setState({
        options,
        loading: false,
      })
    })
  }

  getNoResultsMessage() {
    const { inputValue, loading } = this.state
    const {
      minChars,
      placeholderMessage,
      loadingMessage,
      noResultsMessage,
    } = this.props

    if (inputValue.length < minChars) {
      return placeholderMessage
    }

    if (loading) {
      return loadingMessage
    }

    return noResultsMessage
  }

  handleInputChange(e) {
    const { minChars, debounceWait, onChange } = this.props
    const { value } = e.target

    this.setState({
      inputValue: e.target.value,
      loading: true,
    })

    if (value.length < minChars) {
      this.setState({
        options: [],
      })
      return
    }

    debounce(this.getOptions, debounceWait)(value)

    if (onChange) {
      onChange(e)
    }
  }

  render() {
    const { inputValue, options } = this.state
    const { children } = this.props

    const props = {
      ...filterProps(this.props, [
        'loadOptions',
        'debounceWait',
        'children',
        'minChars',
        'placeholderMessage',
        'loadingMessage',
      ]),
      inputValue,
      async: true,
      options,
      onChange: this.handleInputChange,
      noResultsMessage: this.getNoResultsMessage(),
    }

    if (children) {
      return React.cloneElement(React.Children.only(children), props)
    }

    return <AutocompleteField {...props} />
  }
}

AsyncAutocompleteField.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  minChars: PropTypes.number,
  debounceWait: PropTypes.number,
  children: PropTypes.node,
  onChange: PropTypes.func,
  placeholderMessage: PropTypes.node,
  loadingMessage: PropTypes.node,
  noResultsMessage: PropTypes.node,
}

AsyncAutocompleteField.defaultProps = {
  minChars: 2,
  debounceWait: 200,
  children: undefined,
  onChange: undefined,
  placeholderMessage: 'Start typing to search',
  loadingMessage: <LoadingMessage />,
  noResultsMessage: undefined,
}

AsyncAutocompleteField.displayName = 'AsyncAutocompleteField'

export default AsyncAutocompleteField
