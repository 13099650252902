import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { VARIANT } from './constants'

/**
 * @see Extends [`Button`](#/Components/Button?id=button-1) by setting relevant `variant`
 * @deprecated Use [`FilledButton`](#/Components/Button?id=filledbutton) instead
 */
const PrimaryButton = ({ children, ...rest }) => (
  <Button {...rest} variant={VARIANT.PRIMARY}>
    {children}
  </Button>
)

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
}

PrimaryButton.displayName = 'PrimaryButton'

export default PrimaryButton
