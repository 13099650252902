export default component => {
  if (!component) return null

  const type = component.type || component

  if (typeof type === 'string') {
    return type
  }
  if (typeof type === 'function') {
    return type.displayName || type.name
  }
  return null
}
