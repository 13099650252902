export default (match, callback) => e => {
  if (
    match.filter(
      key =>
        (e.key && e.key === key.name) || (e.keyCode && e.keyCode === key.code),
    ).length > 0
  ) {
    callback(e)
  }
}
