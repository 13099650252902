import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'

import { Navigation, MenuButton } from '../navigation'
import { Container } from '../container'

import IconNavigation from './IconNavigation'

const MainBar = ({
  logo,
  logoElement,
  toggle,
  menuItems,
  iconNav,
  onSearchSubmit,
  className,
  topBar,
  container,
  navigationType,
  light,
  dark,
  navigationLabel,
  ...rest
}) => {
  const showIconNav = iconNav.length > 0 || onSearchSubmit
  const showNavList = menuItems.length > 0

  const createOnClickHandle = onClick => e => {
    toggle(false)

    if (onClick) {
      onClick(e)
    }
  }

  const addOnClickHandler = menuItem =>
    cloneElement(menuItem, {
      onClick: createOnClickHandle(menuItem.props.onClick),
    })

  return (
    <Navigation.Wrapper
      {...rest}
      title={logo}
      titleElement={logoElement}
      button={
        iconNav.length > 0 ||
        menuItems.length > 0 ||
        Object.keys(topBar).length > 0 ? (
          <MenuButton
            className="ln-c-header__toggle"
            onClick={toggle}
            light={light}
            dark={dark}
          />
        ) : (
          undefined
        )
      }
      container={container}
      className={className}
      label={navigationLabel}
    >
      {(showIconNav || showNavList) && [
        showIconNav && (
          <IconNavigation
            key="iconNav"
            items={iconNav.map(addOnClickHandler)}
            onSearchSubmit={onSearchSubmit}
          />
        ),
        showNavList > 0 && (
          <Navigation.List
            key="mainNav"
            items={menuItems.map(addOnClickHandler)}
            type={[navigationType, light && 'light', dark && 'dark'].filter(
              Boolean,
            )}
          />
        ),
      ]}
    </Navigation.Wrapper>
  )
}

MainBar.propTypes = {
  logo: PropTypes.node.isRequired,
  logoElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  toggle: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  topBar: PropTypes.shape({}),
  iconNav: IconNavigation.propTypes.items,
  onSearchSubmit: IconNavigation.propTypes.onSearchSubmit,
  container: PropTypes.shape(Container.propTypes),
  navigationLabel: PropTypes.string,
  navigationType: PropTypes.string,
  light: PropTypes.bool,
  dark: PropTypes.bool,
}

MainBar.defaultProps = {
  menuItems: [],
  logoElement: undefined,
  className: undefined,
  topBar: {},
  iconNav: IconNavigation.defaultProps.items,
  onSearchSubmit: IconNavigation.defaultProps.onSearchSubmit,
  container: undefined,
  navigationLabel: 'Main menu',
  navigationType: undefined,
  light: false,
  dark: false,
}

MainBar.displayName = 'MainBar'

export default MainBar
