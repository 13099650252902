import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/* eslint-disable react/prefer-stateless-function */
class ToggleButtonGroup extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  getChildren(children) {
    const { value, disabled, alpha } = this.props
    return React.Children.map(children, (child, index) => {
      const childProps = child.props
      return React.cloneElement(child, {
        index,
        disabled,
        alpha,
        active: childProps.value === value,
        onClick: this.handleClick,
      })
    })
  }

  handleClick(event, value) {
    this.props.onChange(event, value, this.props.value)
  }

  render() {
    const { element, children, label, className, alpha, inline } = this.props
    const Element = element
    return (
      <Element
        className={classnames(
          className,
          'ln-c-toggle-button-group',
          alpha && 'ln-c-toggle-button-group--alpha',
          inline && 'ln-c-toggle-button-group--inline',
        )}
        role="group"
        aria-label={label}
      >
        {this.getChildren(children)}
      </Element>
    )
  }
}

ToggleButtonGroup.displayName = 'ToggleButtonGroup'

ToggleButtonGroup.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  /** Array of `ToggleButton` components */
  children: PropTypes.node.isRequired,
  /** `aria-label` applied to the toggle button group (useful for screen readers) */
  label: PropTypes.node,
  /** The value of the item that is currently active / selected */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** On change handler */
  onChange: PropTypes.func.isRequired,
  /** Additional classes to be added to the top-level element */
  className: PropTypes.string,
  /** Displays the alpha styling variant */
  alpha: PropTypes.bool,
  /** Display using inline flex (sized to fit contents) */
  inline: PropTypes.bool,
  /** Disables all toggle button items */
  disabled: PropTypes.bool,
}

ToggleButtonGroup.defaultProps = {
  element: 'div',
  label: undefined,
  value: undefined,
  className: undefined,
  alpha: false,
  inline: false,
  disabled: false,
}

export default ToggleButtonGroup
