import React from 'react'
import PropTypes from 'prop-types'

import { Heading } from '../typography'

const Legend = ({ children, headingLevel, ...rest }) => (
  <Heading {...rest} element="legend" level={headingLevel}>
    {children}
  </Heading>
)

Legend.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fontStyle: PropTypes.string,
  headingLevel: PropTypes.number,
}

Legend.defaultProps = {
  children: undefined,
  className: undefined,
  fontStyle: undefined,
  headingLevel: 3,
}

Legend.displayName = 'Legend'

export default Legend
