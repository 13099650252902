import React from 'react'
import PropTypes from 'prop-types'

import { Search } from '@jsluna/icons'
import { SearchButton } from '@jsluna/button'

import TextInput from './TextInput'
import InputGroup from './InputGroup'
import InputControl from './InputControl'
import InputIcon from './InputIcon'
import InputAction from './InputAction'
import InputButton from './InputButton'

const SearchInput = ({
  className,
  icon,
  action,
  button,
  hasIcon,
  hasAction,
  hasButton,
  ...props
}) => (
  <InputGroup className={className}>
    {hasIcon && <InputIcon>{icon}</InputIcon>}
    <InputControl>
      <TextInput type="search" {...props} />
    </InputControl>
    {hasAction && <InputAction>{action}</InputAction>}
    {hasButton && <InputButton>{button}</InputButton>}
  </InputGroup>
)

SearchInput.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  action: PropTypes.node,
  button: PropTypes.node,
  hasIcon: PropTypes.bool,
  hasAction: PropTypes.bool,
  hasButton: PropTypes.bool,
}

SearchInput.defaultProps = {
  className: undefined,
  icon: <Search />,
  button: undefined,
  action: <SearchButton inputAction />,
  hasIcon: false,
  hasAction: false,
  hasButton: false,
}

SearchInput.displayName = 'SearchInput'

export default SearchInput
