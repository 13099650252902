import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  compose,
  setPropTypes,
  withState,
  withHandlers,
  setDisplayName,
} from 'recompose'

import filterProps from '../../utils/filterProps'
import { Container } from '../container'

import TopBar from './TopBar'
import MainBar from './MainBar'
import toggleHandler from './toggleHandler'

const Header = ({
  className,
  mainId,
  open,
  toggle,
  topBar,
  logo,
  logoElement,
  iconNav,
  menuItems,
  onSearchSubmit,
  container,
  navigationType,
  light,
  dark,
  navigationLabel,
  ...rest
}) => {
  const containerProps = {
    ...container,
    soft: container.soft !== undefined ? container.soft : true,
  }
  return (
    <header
      {...filterProps(rest, ['onMenuClick'])}
      className={classnames('ln-c-header', className, {
        'is-open': open,
      })}
    >
      <a href={`#${mainId}`} className="ln-u-visually-hidden">
        Skip Navigation
      </a>
      <button
        type="button"
        className="ln-c-header__toggle-overlay js-header-toggle"
        title="Toggle Menu"
        aria-hidden="true"
        tabIndex="-1"
        onClick={toggle}
      />

      <div className="ln-c-header__inner">
        {Object.keys(topBar).length > 0 && (
          <TopBar
            {...topBar}
            toggle={toggle}
            hasDivider={iconNav.length > 0 || menuItems.length > 0}
            container={containerProps}
          />
        )}
        <MainBar
          logo={logo}
          logoElement={logoElement}
          toggle={toggle}
          menuItems={menuItems}
          iconNav={iconNav}
          onSearchSubmit={onSearchSubmit}
          topBar={topBar}
          container={containerProps}
          navigationType={navigationType}
          light={light}
          dark={dark}
          navigationLabel={navigationLabel}
        />
      </div>
    </header>
  )
}

Header.propTypes = {
  mainId: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  topBar: PropTypes.shape({
    ...TopBar.propTypes,
  }),
  ...MainBar.propTypes,
  iconNav: PropTypes.arrayOf(PropTypes.element),
  container: PropTypes.shape(Container.propTypes),
}

Header.defaultProps = {
  mainId: 'main-content',
  className: undefined,
  topBar: {},
  ...MainBar.defaultProps,
  container: Container.defaultProps,
}

Header.displayName = 'Header'

export { Header as HeaderComponent }
export default compose(
  setPropTypes({
    ...Header.propTypes,
    open: PropTypes.bool,
    toggle: PropTypes.func,
  }),
  setDisplayName('Header'),
  withState('open', 'onMenuClick', false),
  withHandlers({
    toggle: toggleHandler,
  }),
)(Header)
