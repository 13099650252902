import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const SIZE = 48
const RADIUS = 18
const THICKNESS = 5
const CIRCUMFERENCE = (2 * Math.PI * RADIUS).toFixed(2)

const circleProps = {
  cx: SIZE / 2,
  cy: SIZE / 2,
  r: RADIUS,
  fill: 'none',
  strokeWidth: THICKNESS,
  stroke: 'currentColor',
}

const ProgressSpinner = ({
  className,
  color,
  size,
  value,
  standalone,
  ...rest
}) => {
  const isDeterminate = value !== undefined

  return (
    <svg
      {...rest}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames(
        'ln-c-progress-spinner',
        standalone && 'ln-c-progress-spinner--standalone',
        color && `ln-c-progress-spinner--${color}`,
        size && `ln-c-progress-spinner--${size}`,
        isDeterminate && 'ln-c-progress-spinner--determinate',
        className,
      )}
    >
      <circle {...circleProps} className="ln-c-progress-spinner__bg" />
      <circle
        {...circleProps}
        className="ln-c-progress-spinner__fill"
        strokeDasharray={
          isDeterminate
            ? `${CIRCUMFERENCE}px`
            : `${(CIRCUMFERENCE / 4).toFixed(2)}px ${(
                CIRCUMFERENCE * 0.75
              ).toFixed(2)}px`
        }
        strokeDashoffset={
          isDeterminate ? ((100 - value) / 100) * CIRCUMFERENCE : undefined
        }
      />
    </svg>
  )
}

ProgressSpinner.propTypes = {
  className: PropTypes.string,
  /** Use a different theme colour */
  color: PropTypes.oneOf(['dark', 'light']),
  /** Set className for custom spinner size */
  size: PropTypes.string,
  /** Uses large sizing on larger screen sizes */
  standalone: PropTypes.bool,
  /** Percentage value of progress if determinate */
  value: PropTypes.number,
}

ProgressSpinner.defaultProps = {
  className: undefined,
  value: undefined,
  color: undefined,
  size: undefined,
  standalone: false,
}

ProgressSpinner.displayName = 'ProgressSpinner'

export default ProgressSpinner
