import React from 'react'
import PropTypes from 'prop-types'

const Input = ({
  id,
  name,
  type,
  placeholder,
  disabled,
  onBlur,
  onChange,
  onFocus,
  value,
  defaultValue,
  checked,
  defaultChecked,
  minLength,
  maxLength,
  min,
  max,
  step,
  autoComplete,
  autoCapitalize,
  required,

  inputMode,
  pattern,
  readOnly,
  spellCheck,
  tabIndex,
  className,
  invalid,
  innerRef,
  ...rest
}) => (
  <input
    {...rest}
    id={id || name}
    name={name}
    type={type}
    className={className}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    value={value}
    defaultValue={defaultValue}
    checked={checked}
    defaultChecked={defaultChecked}
    placeholder={placeholder}
    tabIndex={tabIndex}
    autoComplete={autoComplete}
    autoCapitalize={autoCapitalize}
    inputMode={inputMode}
    spellCheck={spellCheck}
    required={required}
    minLength={minLength}
    maxLength={maxLength}
    min={min}
    max={max}
    step={step}
    pattern={pattern}
    readOnly={readOnly}
    disabled={disabled}
    aria-disabled={disabled || undefined}
    aria-invalid={required ? invalid : invalid === true || undefined}
    ref={innerRef}
  />
)

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  autoComplete: PropTypes.string,
  autoCapitalize: PropTypes.string,
  required: PropTypes.bool,
  inputMode: PropTypes.string,
  pattern: PropTypes.oneOfType([
    PropTypes.instanceOf(RegExp),
    PropTypes.string,
  ]),
  readOnly: PropTypes.bool,
  spellCheck: PropTypes.oneOf(['default', true, false]),
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  invalid: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

Input.defaultProps = {
  id: undefined,
  type: 'text',
  disabled: false,
  placeholder: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  value: undefined,
  defaultValue: undefined,
  checked: undefined,
  defaultChecked: undefined,
  minLength: undefined,
  maxLength: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  autoComplete: undefined,
  autoCapitalize: undefined,
  required: undefined,
  inputMode: undefined,
  pattern: undefined,
  readOnly: false,
  spellCheck: undefined,
  tabIndex: undefined,
  className: undefined,
  invalid: false,
  innerRef: undefined,
}

Input.displayName = 'Input'

export default Input
