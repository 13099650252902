import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProgressBar = ({
  element,
  className,
  size,
  standalone,
  value,
  color,
  ...rest
}) => {
  const Element = element
  const isDeterminate = value !== undefined

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-progress-bar',
        size && `ln-c-progress-bar--${size}`,
        standalone && 'ln-c-progress-bar--standalone',
        color && `ln-c-progress-bar--${color}`,
        isDeterminate && 'ln-c-progress-bar--determinate',
        className,
      )}
    >
      {isDeterminate && (
        <div
          className="ln-c-progress-bar__fill"
          style={{
            width: `${value}%`,
          }}
        />
      )}
    </Element>
  )
}

ProgressBar.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  /** Use a different theme colour */
  color: PropTypes.oneOf(['dark', 'light']),
  /** Use a different bar height */
  size: PropTypes.oneOf(['small']),
  /** Uses larger bar sizing only on wider screen sizes */
  standalone: PropTypes.bool,
  /** Percentage value of progress if determinate */
  value: PropTypes.number,
}

ProgressBar.defaultProps = {
  element: 'div',
  className: undefined,
  color: undefined,
  size: undefined,
  standalone: false,
  value: undefined,
}

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar
