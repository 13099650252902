import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TabPanel = ({
  element,
  children,
  className,
  active,
  inactive,
  ...rest
}) => {
  const Element = element
  const activeState = active || !inactive

  return (
    <Element
      {...rest}
      role="tabpanel"
      aria-hidden={!activeState}
      className={classnames(className, !activeState && 'ln-u-hidden')}
    >
      {children}
    </Element>
  )
}
TabPanel.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** @deprecated Use inactive */
  active: PropTypes.bool,
  inactive: PropTypes.bool,
}

TabPanel.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  active: undefined,
  inactive: false,
}

TabPanel.displayName = 'TabPanel'

export default TabPanel
