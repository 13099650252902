import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import LabelInfo from './LabelInfo'

const DEFAULT_REQUIRED_INDICATOR = ''
const DEFAULT_OPTIONAL_INDICATOR = '(optional)'

const Label = ({
  element,
  htmlFor,
  requiredLabel,
  optionalLabel,
  children,
  className,
  hidden,
  ...rest
}) => {
  const Element = element
  let indicator

  if (optionalLabel !== undefined) {
    indicator =
      optionalLabel === true ? DEFAULT_OPTIONAL_INDICATOR : optionalLabel
  }

  if (requiredLabel !== undefined) {
    indicator =
      requiredLabel === true ? DEFAULT_REQUIRED_INDICATOR : requiredLabel
  }

  return (
    <Element
      {...rest}
      htmlFor={htmlFor}
      className={classnames(
        className,
        'ln-c-label',
        hidden && 'ln-u-visually-hidden',
      )}
    >
      {children}
      {indicator && <LabelInfo> {indicator}</LabelInfo>}
    </Element>
  )
}

Label.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  htmlFor: PropTypes.string.isRequired,
  requiredLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  optionalLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  hidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Label.defaultProps = {
  element: 'label',
  requiredLabel: undefined,
  optionalLabel: undefined,
  hidden: false,
  className: undefined,
}

Label.displayName = 'Label'

export default Label
