import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

const Heading = ({ children, element, level, typeStyle, ...rest }) => {
  let props = {}
  const hElement = element || `h${level}`

  if (typeof hElement !== 'string' || !/h[1-6]/i.test(hElement)) {
    props = {
      role: 'heading',
      'aria-level': level,
      typeStyle: typeStyle || `h${level}`,
    }
  }

  return (
    <Text {...rest} typeStyle={typeStyle} element={hElement} {...props}>
      {children}
    </Text>
  )
}

Heading.propTypes = {
  children: PropTypes.node,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  level: PropTypes.number,
  typeStyle: PropTypes.string,
}

Heading.defaultProps = {
  children: undefined,
  element: undefined,
  level: 1,
  typeStyle: undefined,
}

Heading.displayName = 'Heading'

export default Heading
