/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import PaginationEllipsis from './PaginationEllipsis'
import PaginationPage from './PaginationPage'

import { getPages } from './utils'
import {
  ELLIPSIS,
  LINK_REL,
  TYPE_PREVIOUS,
  TYPE_NEXT,
  TYPE_FIRST,
  TYPE_LAST,
} from './constants'

const getLinkRel = ({ page, current, total }) => {
  if (page === 1) {
    return LINK_REL[TYPE_FIRST]
  }

  if (page === total) {
    return LINK_REL[TYPE_LAST]
  }

  if (page === current - 1) {
    return LINK_REL[TYPE_PREVIOUS]
  }

  if (page === current + 1) {
    return LINK_REL[TYPE_NEXT]
  }

  return undefined
}

const PaginationPages = props => {
  const { onChange, current, total, createHref, element, range } = props

  return getPages(current, total, range).map(
    ({ page }, index) =>
      page === ELLIPSIS ? (
        <PaginationEllipsis key={index} />
      ) : (
        <PaginationPage
          key={index}
          page={page}
          onClick={onChange}
          href={createHref(page)}
          current={current === page}
          element={element}
          rel={getLinkRel({ page, current, total })}
        />
      ),
  )
}

PaginationPages.propTypes = {
  range: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  createHref: PropTypes.func,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ]),
}

PaginationPages.defaultProps = {
  onChange: undefined,
  createHref: undefined,
}

export default PaginationPages
