const matchOperators = /[|\\{}()[\]^$+*?.]/g

const escapeStringRegExp = str => {
  if (typeof str !== 'string') {
    throw new TypeError('Expected a string')
  }

  return str.replace(matchOperators, '\\$&')
}

const createFilter = (inputValue, getOptionLabel) => option =>
  new RegExp(escapeStringRegExp(inputValue), 'gi').test(getOptionLabel(option))

export default createFilter
