import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Card = ({
  element,
  className,
  hard,
  children,
  color,
  rounded,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames('ln-c-card', className, {
        'ln-c-card--soft': !hard,
        [`ln-c-card--${color}`]: color,
        'ln-c-card--rounded': rounded,
      })}
    >
      {children}
    </Element>
  )
}

Card.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hard: PropTypes.bool,
  color: PropTypes.string,
  rounded: PropTypes.bool,
}

Card.defaultProps = {
  element: 'div',
  className: undefined,
  hard: false,
  color: undefined,
  rounded: false,
}

Card.displayName = 'Card'

export default Card
