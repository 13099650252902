import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const responsiveClassName = (className, prefix = '') =>
  className &&
  (typeof className === 'object'
    ? Object.keys(className).map(
        breakpoint =>
          breakpoint !== 'default'
            ? `ln-u-${prefix}${className[breakpoint]}@${breakpoint}`
            : `ln-u-${prefix}${className[breakpoint]}`,
      )
    : `ln-u-${prefix}${className}`)

const GridItem = ({
  element,
  className,
  children,
  size,
  offsetLeft,
  offsetRight,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        className,
        'ln-o-grid__item',
        responsiveClassName(size),
        responsiveClassName(offsetLeft, 'pull-'),
        responsiveClassName(offsetRight, 'push-'),
      )}
    >
      {children}
    </Element>
  )
}

GridItem.displayName = 'GridItem'

GridItem.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  /** Width of item as a fraction value, can supply an object with responsive values */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** Push content by either a static or responsive fractions */
  offsetLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Push content by either a static or responsive fractions */
  offsetRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

GridItem.defaultProps = {
  element: 'div',
  className: undefined,
  offsetLeft: undefined,
  offsetRight: undefined,
}

export default GridItem
