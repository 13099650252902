import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ButtonGroupWrapper = ({
  element,
  children,
  className,
  actionBar,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames(
        className,
        'ln-c-button-group',
        actionBar ? 'ln-c-button-group--action-bar' : 'ln-o-clearfix',
      )}
    >
      {children}
    </Element>
  )
}

ButtonGroupWrapper.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  actionBar: PropTypes.bool,
}

ButtonGroupWrapper.defaultProps = {
  element: 'div',
  className: undefined,
  actionBar: false,
}

ButtonGroupWrapper.displayName = 'ButtonGroupWrapper'

export default ButtonGroupWrapper
