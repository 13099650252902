import React from 'react'
import PropTypes from 'prop-types'

import { calcOrdinal } from './utils'
import { TYPE_PAGE } from './constants'

import PaginationItem from './PaginationItem'

const buildLabel = (page, current) =>
  `${current ? 'Current page, ' : ''}${calcOrdinal(page)} page`

const PaginationPage = props => (
  <PaginationItem
    {...props}
    type={TYPE_PAGE}
    label={buildLabel(props.page, props.current)}
  >
    {props.page}
  </PaginationItem>
)

PaginationPage.propTypes = {
  page: PropTypes.number,
  current: PropTypes.bool,
}

PaginationPage.defaultProps = {
  page: undefined,
  current: false,
}

PaginationPage.displayName = 'PaginationPage'

export default PaginationPage
