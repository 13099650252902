import includes from './arrayIncludes'

export default (props, filterList) =>
  Object.keys(props).reduce((newProps, prop) => {
    if (includes(filterList, prop)) {
      return newProps
    }
    return {
      ...newProps,
      [prop]: props[prop],
    }
  }, {})
