import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProgressBar = ({
  element,
  className,
  small,
  standalone,
  value,
  light,
  dark,
  ...rest
}) => {
  const Element = element
  const isDeterminate = value !== undefined

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-progress-bar',
        small && 'ln-c-progress-bar--small',
        standalone && 'ln-c-progress-bar--standalone',
        light && 'ln-c-progress-bar--light',
        dark && 'ln-c-progress-bar--dark',
        isDeterminate && 'ln-c-progress-bar--determinate',
        className,
      )}
    >
      {isDeterminate && (
        <div
          className="ln-c-progress-bar__fill"
          style={{ width: `${value}%` }}
        />
      )}
    </Element>
  )
}

ProgressBar.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  /** Use light theme colour */
  light: PropTypes.bool,
  /** Use dark theme colour */
  dark: PropTypes.bool,
  /** Use the smaller bar height */
  small: PropTypes.bool,
  /** Uses larger bar sizing only on wider screen sizes */
  standalone: PropTypes.bool,
  /** Percentage value of progress if determinate */
  value: PropTypes.number,
}

ProgressBar.defaultProps = {
  element: 'div',
  className: undefined,
  light: false,
  dark: false,
  small: false,
  standalone: false,
  value: undefined,
}

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar
