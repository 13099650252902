const toggleHandler = ({ onExpandClick, open }) => () => onExpandClick(!open)

const changeHandler = ({ onChange, changeValue }) => e => {
  changeValue(e.target.value)

  if (onChange) {
    onChange(e)
  }
}

const submitHandler = ({ onSubmit, value }) => e => {
  onSubmit(value)
  e.preventDefault()
}

export { toggleHandler, changeHandler, submitHandler }
