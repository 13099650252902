import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ResultList = ({
  getOptionLabel,
  getOptionValue,
  getMenuProps,
  getItemProps,
  options,
  isOpen,
  selectedItem,
  highlightedIndex,
  optionRenderer,
  verticalAlign,
  className,
  noResultsMessage,
  ...rest
}) => (
  <ul
    {...rest}
    {...getMenuProps()}
    className={classnames(
      'ln-c-dropdown-list',
      'ln-c-dropdown-list--overlay',
      className,
    )}
  >
    {isOpen &&
      (options.length > 0 ? (
        /* eslint-disable jsx-a11y/role-supports-aria-props */
        options.map((option, index) => (
          <li
            className={classnames(
              'ln-c-dropdown-list__item',
              verticalAlign &&
                `ln-c-dropdown-list__item--align-${verticalAlign}`,
              selectedItem === option && 'is-selected',
              highlightedIndex === index && 'is-hover',
            )}
            {...getItemProps({
              key: getOptionValue(option),
              item: option,
              index,
            })}
            aria-selected={selectedItem === option || undefined}
          >
            {optionRenderer ? optionRenderer(option) : getOptionLabel(option)}
          </li>
        ))
      ) : (
        <li
          className={classnames(
            'ln-c-dropdown-list__item',
            'ln-c-dropdown-list__item--message',
            verticalAlign && `ln-c-dropdown-list__item--align-${verticalAlign}`,
          )}
        >
          {noResultsMessage}
        </li>
      ))}
  </ul>
)

ResultList.propTypes = {
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool,
  optionRenderer: PropTypes.func,
  selectedItem: PropTypes.shape({}),
  highlightedIndex: PropTypes.number,
  verticalAlign: PropTypes.string,
  className: PropTypes.string,
  noResultsMessage: PropTypes.node,
}

ResultList.defaultProps = {
  options: [],
  isOpen: false,
  optionRenderer: undefined,
  selectedItem: undefined,
  highlightedIndex: undefined,
  verticalAlign: 'center',
  className: undefined,
  noResultsMessage: 'No results found',
}

ResultList.displayName = 'ResultList'

export default ResultList
