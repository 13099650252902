import { Children, Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class ClickAway extends Component {
  constructor(props) {
    super(props)

    this.mounted = false
    this.selfNode = null

    this.handleClickAway = this.handleClickAway.bind(this)
  }

  componentDidMount() {
    this.selfNode = ReactDOM.findDOMNode(this) // eslint-disable-line react/no-find-dom-node
    this.mounted = true
    document.addEventListener('click', this.handleClickAway)
  }

  componentWillUnmount() {
    this.mounted = false
    document.removeEventListener('click', this.handleClickAway)
  }

  handleClickAway(event) {
    const { mounted, selfNode } = this
    const { inactive, onClickAway } = this.props

    if (inactive || !mounted || !selfNode) {
      return
    }

    if (
      document.documentElement &&
      document.documentElement.contains(event.target) &&
      !selfNode.contains(event.target)
    ) {
      onClickAway(event)
    }
  }

  render() {
    const { children } = this.props

    return Children.only(children)
  }
}

ClickAway.propTypes = {
  onClickAway: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  inactive: PropTypes.bool,
}

ClickAway.defaultProps = {
  inactive: false,
}

ClickAway.displayName = 'ClickAway'

export default ClickAway
