import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const PaginationInfo = ({ children, current, total }) => (
  <li className="ln-c-pagination__info" aria-live="polite" aria-atomic="true">
    {children || (
      <Fragment>
        <span className="ln-u-visually-hidden@max-xs">Page&nbsp;</span>
        {current} of {total}
      </Fragment>
    )}
  </li>
)

PaginationInfo.displayName = 'PaginationInfo'

PaginationInfo.propTypes = {
  children: PropTypes.node,
  current: PropTypes.number,
  total: PropTypes.number,
}

PaginationInfo.defaultProps = {
  children: undefined,
  current: undefined,
  total: undefined,
}

export default PaginationInfo
