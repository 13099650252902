import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProgressMessage = ({ element, children, className, ...rest }) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames('ln-c-progress-indicator__message', className)}
      role="status"
      aria-live="polite"
    >
      {children}
    </Element>
  )
}

ProgressMessage.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
}

ProgressMessage.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
}

ProgressMessage.displayName = 'ProgressMessage'

export default ProgressMessage
