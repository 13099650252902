import React from 'react'

import { Select } from '../inputs'

import FormGroup from './FormGroup'
import propTypes from './fieldPropTypes'

const SelectField = ({
  className,
  name,
  required,
  requiredLabel,
  optional,
  optionalLabel,
  validationFirst,
  label,
  hideLabel,
  error,
  warning,
  info,
  ...props
}) => (
  <FormGroup
    name={name}
    required={required}
    optional={optional}
    label={label}
    hideLabel={hideLabel}
    error={error}
    warning={warning}
    info={info}
    className={className}
    validationFirst={validationFirst}
  >
    {inputProps => <Select {...inputProps} {...props} />}
  </FormGroup>
)

SelectField.propTypes = propTypes

SelectField.displayName = 'SelectField'

export default SelectField
