import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FooterNav = ({ element, children, className, label, ...rest }) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames('ln-c-footer__nav', className)}
      aria-label={label}
    >
      {children}
    </Element>
  )
}

FooterNav.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Screen reader description for page landmark */
  label: PropTypes.string,
}

FooterNav.defaultProps = {
  element: 'nav',
  children: undefined,
  className: undefined,
  label: 'Footer navigation',
}

FooterNav.displayName = 'FooterNav'

export default FooterNav
