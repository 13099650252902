import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const InputIcon = ({ children }) => {
  const element = React.Children.only(children)

  return React.cloneElement(element, {
    className: classnames(
      'ln-c-input-group__icon',
      element.props && element.props.className,
    ),
    'aria-hidden': true,
  })
}

InputIcon.propTypes = {
  children: PropTypes.node,
}

InputIcon.defaultProps = {
  children: undefined,
}

InputIcon.displayName = 'InputIcon'

export default InputIcon
