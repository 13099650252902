import React from 'react'

import { Cancel } from '@jsluna/icons'

import Button from './Button'

/**
 * Helper button used primarily inside form controls that makes use of the
 * [`Cancel`](#/Icons) icon
 * @see Extends [`Button`](#/Components/Button?id=button-1)
 */
const CancelButton = props => (
  <Button {...props} hard>
    <Cancel />
  </Button>
)

CancelButton.displayName = 'CancelButton'

export default CancelButton
