import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableHeaderCell = ({
  children,
  className,
  align,
  onSort,
  sortDirection,
  ...rest
}) => (
  <th
    {...rest}
    className={classnames('ln-c-table__header-cell', className, {
      'ln-c-table__header-cell--sortable': !!onSort,
      [`ln-c-table__header-cell--text-align-${align}`]: align,
    })}
  >
    {onSort ? (
      <button
        type="button"
        className={classnames('ln-c-table__sort-button', {
          'is-active': !!sortDirection,
          'is-ascending': sortDirection === 'ascending',
        })}
        onClick={() => onSort(sortDirection === 'ascending')}
      >
        <span className="ln-c-table__sort-text" aria-label="Sort by">
          {children}
        </span>
      </button>
    ) : (
      children
    )}
  </th>
)

TableHeaderCell.propTypes = {
  children: PropTypes.node,
  onSort: PropTypes.func,
  sortDirection: PropTypes.oneOf(['ascending', 'descending']),
  className: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center']),
}

TableHeaderCell.defaultProps = {
  children: undefined,
  onSort: undefined,
  sortDirection: undefined,
  className: undefined,
  align: undefined,
}

TableHeaderCell.displayName = 'TableHeaderCell'

export default TableHeaderCell
