import { compose, withProps, setDisplayName } from 'recompose'

import FormOptionField from './FormOptionField'

/**
 * @see Combines [`FormOptionField`](#/Components/Form?id=formoptionfield) and [`FormGroup`](#/Components/Form?id=formgroup-1) for a more feature rich component.
 */
export default compose(
  setDisplayName('SwitchField'),
  withProps({ optionType: 'switch' }),
)(FormOptionField)
