import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ArrowDown } from '@jsluna/icons'
import { ClickAway, childMatchesType, onKeyPress } from '@jsluna/utils'

import ListGroup from './ListGroup'
import ListGroupItem from './ListGroupItem'

class ListGroupDropdown extends Component {
  constructor(props) {
    super(props)

    this.onKeyPress = onKeyPress([{ name: 'Escape', code: 27 }], e => {
      this.handleEscapePress(e)
    })

    this.listItemRef = null
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false)
  }

  handleEscapePress(e) {
    const { onClose, active } = this.props

    if (onClose && active) {
      onClose(e)
      if (this.listItemRef) {
        this.listItemRef.focus()
      }
    }
  }

  render() {
    const {
      element,
      className,
      containerClassName,
      children,
      active,
      onClose,
      onMouseEnter,
      onMouseLeave,
      hover,
      noIcon,
      position,
      font,
      defaultOpen,
      onItemClick,
      ...rest
    } = this.props

    const listItemLabel = []
    let dropdownMenu = false

    React.Children.forEach(children, child => {
      if (childMatchesType(child, ListGroup)) {
        dropdownMenu = child
      } else {
        listItemLabel.push(child)
      }
    })

    const isOpen = active || hover

    return (
      <ClickAway onClickAway={onClose} inactive={!active}>
        <li
          className={classnames(
            containerClassName,
            'ln-c-list-group__dropdown-container',
          )}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <ListGroupItem
            {...rest}
            element={element}
            containerElement={Fragment}
            className={classnames(className, hover && 'is-hover')}
            active={typeof active !== 'undefined' ? active : defaultOpen}
            activeType={false}
            innerRef={el => {
              this.listItemRef = el
            }}
            aria-haspopup
            aria-expanded={typeof isOpen !== 'undefined' ? isOpen : defaultOpen}
          >
            <Fragment>
              {listItemLabel}
              {!noIcon && (
                <ArrowDown className="ln-c-list-group__dropdown-arrow" />
              )}
            </Fragment>
          </ListGroupItem>
          {dropdownMenu &&
            React.cloneElement(dropdownMenu, {
              className: classnames(
                'ln-c-list-group--dropdown',
                position && `ln-c-list-group--dropdown-${position}`,
                font && `ln-c-list-group--dropdown-${font}`,
              ),
              open: typeof isOpen !== 'undefined' ? isOpen : defaultOpen,
              onDropdownBlur: onClose,
              onItemClick,
            })}
        </li>
      </ClickAway>
    )
  }
}

ListGroupDropdown.propTypes = {
  element: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  /** ClassName to be applied to the wrapping `li`  */
  containerClassName: PropTypes.string,
  /** Should the item be highlighted within the list */
  active: PropTypes.bool,
  /** Handle dropdown close event on outside click */
  onClose: PropTypes.func,
  /** Handler for setting the hover state if required */
  onMouseEnter: PropTypes.func,
  /** Handler for removing the hover state if required */
  onMouseLeave: PropTypes.func,
  /** Whether the dropdown is currently hovered if you wish for it to open on mouse over  */
  hover: PropTypes.bool,
  /** Disables the arrow icon from showing */
  noIcon: PropTypes.bool,
  /** Alignment of the dropdown menu in relation to the trigger */
  position: PropTypes.oneOf(['left', 'right']),
  /** Type style to use for the ListItems */
  font: PropTypes.oneOf(['body', 'button']),
  /** Whether the dropdown should be expanded by default */
  defaultOpen: PropTypes.bool,
  /** See ListGroup */
  onItemClick: PropTypes.func,
}

ListGroupDropdown.defaultProps = {
  element: 'button',
  children: undefined,
  className: undefined,
  containerClassName: undefined,
  active: undefined,
  onClose: () => {},
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  hover: undefined,
  noIcon: false,
  position: undefined,
  font: undefined,
  defaultOpen: false,
  onItemClick: undefined,
}

ListGroupDropdown.displayName = 'ListGroupDropdown'

export default ListGroupDropdown
