export default (child, type, checkDisplayName = false) => {
  let matches = false

  if (!child || !child.type) {
    return matches
  }

  matches = Array.isArray(type)
    ? type.includes(child.type)
    : child.type === type

  if (checkDisplayName && !matches) {
    const name = child.type.displayName || child.type.name
    matches = type.displayName === name
  }

  return matches
}
