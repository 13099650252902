import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/**
 * @see [`ToggleButtonGroup`](#/Components/Button?id=togglebuttongroup) for a demo of toggle buttons used in a group.
 */
const ToggleButton = ({
  element,
  children,
  value,
  className,
  alpha,
  active,
  disabled,
  onClick,
  role,
  ...rest
}) => {
  const Item = element

  const props = {
    ...rest,
    className: classnames(
      className,
      'ln-c-toggle-button',
      alpha && 'ln-c-toggle-button--alpha',
      active && 'is-active',
      disabled && 'is-disabled',
    ),
    role,
    onClick: onClick && (event => onClick(event, value)),
    'aria-pressed': active,
    'aria-disabled': disabled,
  }

  if (Item === 'button') {
    props.disabled = disabled
    props.type = 'button'
  }

  return <Item {...props}>{children}</Item>
}

ToggleButton.displayName = 'ToggleButton'

ToggleButton.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ]),
  /** Content displayed by the item */
  children: PropTypes.node.isRequired,
  /** Value of the button when active */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Additional classes to be added to the top-level element */
  className: PropTypes.string,
  /** Displays the alpha styling variant */
  alpha: PropTypes.bool,
  /** Is active / selected */
  active: PropTypes.bool,
  /** Is disabled */
  disabled: PropTypes.bool,
  /** On click handler */
  onClick: PropTypes.func,
  /** `role` attribute */
  role: PropTypes.string,
}

ToggleButton.defaultProps = {
  element: 'button',
  className: undefined,
  alpha: false,
  active: false,
  disabled: false,
  onClick: undefined,
  role: undefined,
}

export default ToggleButton
