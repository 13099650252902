import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { TextArea } from '../inputs'

import FormGroup from './FormGroup'
import propTypes from './fieldPropTypes'

/**
 * @see Combines [`TextArea`](#/Components/Inputs?id=textarea) and [`FormGroup`](#/Components/Form?id=formgroup-1)
 * for a more feature rich component. See their component demos for extended functionality.
 */
class TextAreaField extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props.defaultValue || '' }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({ value: event.target.value })

    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  render() {
    const {
      name,
      characterCount,
      className,
      required,
      requiredLabel,
      optional,
      optionalLabel,
      validationFirst,
      label,
      hideLabel,
      error,
      warning,
      info,
      ...props
    } = this.props
    const counterId = `${name}Counter`

    return (
      <FormGroup
        name={name}
        required={required}
        optional={optional}
        label={label}
        hideLabel={hideLabel}
        error={error}
        warning={warning}
        info={info}
        className={className}
        validationFirst={validationFirst}
      >
        {({ 'aria-describedby': describedBy, ...inputProps }) => (
          <Fragment>
            <TextArea
              {...inputProps}
              aria-describedby={
                describedBy || characterCount
                  ? `${describedBy || ''} ${characterCount ? counterId : ''}`
                  : undefined
              }
              {...props}
              onChange={this.handleChange}
              value={this.state.value}
            />
            {characterCount &&
              props.maxLength && (
                <p id={counterId} aria-live="polite" aria-atomic="true">
                  {this.state.value.length}
                  <span className="ln-u-visually-hidden"> out of </span>
                  <span aria-hidden="true"> / </span>
                  {props.maxLength}
                </p>
              )}
          </Fragment>
        )}
      </FormGroup>
    )
  }
}

TextAreaField.getDerivedStateFromProps = (props, state) =>
  props.value !== undefined && props.value !== state.value
    ? { value: props.value }
    : null

TextAreaField.propTypes = {
  ...propTypes,
  characterCount: PropTypes.bool,
}

TextAreaField.defaultProps = {
  characterCount: false,
}

TextAreaField.displayName = 'TextAreaField'

export default TextAreaField
