import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import Password from './Password'
import FormGroup from './FormGroup'
import propTypes from './utils/fieldPropTypes'

const DEFAULT_LEVELS = {
  0: {
    className: '',
    caption: 'Too short',
    test: () => true,
  },
  1: {
    className: 'is-level-1',
    caption: 'Bad',
    test: value => value.length > 7,
  },
  2: {
    className: 'is-level-2',
    caption: 'Ok',
    test: value => value.length > 9,
  },
  3: {
    className: 'is-level-3',
    caption: 'Good',
    test: value => value.length > 11,
  },
  4: {
    className: 'is-level-4',
    caption: 'Great',
    test: value => value.length > 13,
  },
}

const getLevel = (value, levelMap) => {
  const levels = Object.keys(levelMap)
  const reversedLevels = levels.reverse()

  if (value !== undefined) {
    for (let i = 0; i < levels.length; i += 1) {
      if (levelMap[reversedLevels[i]].test(value)) {
        return levelMap[reversedLevels[i]]
      }
    }
  }

  return levelMap[levels[0]]
}

/**
 * @see Combines [`Password`](#/Components/Inputs?id=password) and [`FormGroup`](#/Components/Form?id=formgroup-1) for a more feature rich component.
 */
class PasswordField extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props.defaultValue || '' }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const { onChange } = this.props

    this.setState({ value: event.target.value })

    if (onChange) {
      onChange(event)
    }
  }

  render() {
    const { value } = this.state
    const {
      className,
      error,
      hideLabel,
      info,
      label,
      labelElement,
      levels,
      name,
      optional,
      required,
      strengthIndicator,
      validationFirst,
      warning,
      ...props
    } = this.props
    const strengthIndicatorId = `${name}StrengthIndicator`

    const level = getLevel(value, levels)

    return (
      <FormGroup
        className={className}
        error={error}
        hideLabel={hideLabel}
        info={info}
        label={label}
        labelElement={labelElement}
        name={name}
        optional={optional}
        required={required}
        validationFirst={validationFirst}
        warning={warning}
      >
        {({ 'aria-describedby': describedBy, ...inputProps }) => (
          <Fragment>
            <Password
              {...inputProps}
              aria-describedby={
                describedBy
                  ? `${describedBy} ${strengthIndicatorId} `
                  : strengthIndicatorId
              }
              {...props}
              value={value}
              onChange={this.handleChange}
            />
            {strengthIndicator && (
              <div
                className={`ln-c-password-strength-meter ${level.className}`}
              >
                <p
                  id={strengthIndicatorId}
                  className="ln-c-password-strength-meter__label"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  Strength:{' '}
                  <span className="ln-c-password-strength-meter__value">
                    {level.caption}
                  </span>
                </p>
              </div>
            )}
          </Fragment>
        )}
      </FormGroup>
    )
  }
}

PasswordField.getDerivedStateFromProps = (props, state) =>
  props.value !== undefined && props.value !== state.value
    ? { value: props.value }
    : null

PasswordField.propTypes = {
  ...propTypes,
  levels: PropTypes.shape({}),
  strengthIndicator: PropTypes.bool,
}

PasswordField.defaultProps = {
  levels: DEFAULT_LEVELS,
  strengthIndicator: false,
}

PasswordField.displayName = 'PasswordField'

export { DEFAULT_LEVELS as defaultLevels, getLevel }

export default PasswordField
