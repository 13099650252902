import { compose, withProps, setDisplayName } from 'recompose'

import Text from './Text'

const getDisplayName = typeStyle =>
  `${typeStyle.charAt(0).toUpperCase()}${typeStyle
    .substring(1)
    .replace('-', '')}`

export default typeStyle =>
  compose(
    setDisplayName(getDisplayName(typeStyle)),
    withProps({
      typeStyle,
    }),
  )(Text)
