import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { VARIANT } from './constants'

const baseClassName = 'ln-c-button'

/**
 * Base component extended by all other button variants
 */
const Button = ({
  element,
  children,
  className,
  disabled,
  fullWidth,
  onClick,
  type,
  innerRef,
  circle,
  hard,
  role,
  variant,
  color,
  ...rest
}) => {
  const buttonEl = element

  let props = {
    ...rest,
    className: classnames(
      baseClassName,
      variant && `${baseClassName}--${variant}`,
      fullWidth && `${baseClassName}--full`,
      circle && `${baseClassName}--circle`,
      hard && `${baseClassName}--hard`,
      color && `${baseClassName}--${color}`,
      disabled && 'is-disabled',
      className,
    ),
    'aria-disabled': disabled || undefined,
    role,
    onClick,
    ref: innerRef,
  }

  if (buttonEl === 'button') {
    props = {
      ...props,
      role: role === 'button' ? undefined : role,
      disabled,
      type,
    }
  }

  return React.isValidElement(buttonEl)
    ? React.cloneElement(buttonEl, props, children)
    : React.createElement(buttonEl, props, children)
}

Button.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node.isRequired,
  /** Resets padding and adds border radius to be circular in shape */
  circle: PropTypes.bool,
  className: PropTypes.string,
  /** Colour scheme to be used */
  color: PropTypes.string,
  disabled: PropTypes.bool,
  /** Block level display to take up full width of container */
  fullWidth: PropTypes.bool,
  /** Removes padding from the ends of the button */
  hard: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Determines the display style used e.g. filled / outlined  */
  variant: PropTypes.oneOf(Object.keys(VARIANT).map(key => VARIANT[key])),
}

Button.defaultProps = {
  circle: false,
  className: undefined,
  disabled: false,
  element: 'button',
  fullWidth: false,
  innerRef: undefined,
  role: 'button',
  hard: false,
  type: 'button',
  variant: undefined,
  color: undefined,
}

Button.displayName = 'Button'

export default Button
