import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Footer = ({ children, className, ...rest }) => (
  <footer
    {...rest}
    className={classnames('ln-c-footer', className)}
    role="contentinfo"
  >
    {children}
  </footer>
)

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

Footer.defaultProps = {
  children: undefined,
  className: undefined,
}

Footer.displayName = 'Footer'

export default Footer
