import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { getUniqueId, filterProps } from '@jsluna/utils'

import ProgressMessage from './ProgressMessage'
import ProgressSpinner from './ProgressSpinner'
import ProgressBar from './ProgressBar'

const isIndicator = component =>
  component &&
  component.type &&
  (component.type === ProgressSpinner || component.type === ProgressBar)

class ProgressIndicator extends Component {
  constructor(props) {
    super(props)

    this.messageId = props.id
      ? `${props.id}-message`
      : getUniqueId('progress-indicator-')
    this.focussedEl = null
    this.progressEl = null

    this.setFocus = this.setFocus.bind(this)
    this.returnFocus = this.returnFocus.bind(this)
  }

  componentDidMount() {
    const { loading } = this.props

    if (loading) this.setFocus()
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props

    if (loading && !prevProps.loading) this.setFocus()
    if (!loading && prevProps.loading) this.returnFocus()
  }

  setFocus() {
    const { preventFocus } = this.props

    if (preventFocus) {
      return false
    }

    this.focussedEl = document.activeElement

    if (this.progressEl) {
      this.progressEl.focus()
    }

    return true
  }

  returnFocus() {
    const { preventFocus } = this.props

    if (preventFocus) {
      return false
    }

    if (this.focussedEl) {
      this.focussedEl.focus()
    }

    this.focussedEl = null

    return true
  }

  render() {
    const {
      element,
      children,
      className,
      value,
      page,
      loading,
      message,
      indicator,
      preventFocus,
      ...rest
    } = this.props

    const Element = element

    const progressProps = {
      className,
      role: loading ? 'progressbar' : undefined,
      'aria-valuemin': value ? '0' : undefined,
      'aria-valuemax': value ? '100' : undefined,
      'aria-valuenow': value,
      'aria-busy': loading,
      'aria-label': !message && loading ? 'Loading, please wait.' : undefined,
      'aria-describedby': loading && message ? this.messageId : undefined,
      tabIndex: loading ? 0 : undefined,
    }

    const progressMessage = message
      ? React.cloneElement(message, { id: this.messageId })
      : undefined

    const progressIndicator = indicator
      ? React.cloneElement(indicator, { value })
      : undefined

    if (typeof children === 'function') {
      return children({
        progressProps,
        message: progressMessage,
        indicator: progressIndicator,
      })
    }

    const content = React.Children.map(
      children,
      child => !isIndicator(child) && child,
    ).filter(Boolean)

    return (
      <Element
        {...filterProps(rest, ['preventFocus'])}
        {...progressProps}
        aria-label={content.length ? undefined : progressProps['aria-label']}
        aria-describedby={content.length ? this.messageId : undefined}
        className={classnames(
          'ln-c-progress-indicator',
          page && 'ln-c-progress-indicator--page',
          loading && 'is-loading',
          className,
        )}
        ref={el => {
          this.progressEl = el
        }}
      >
        {loading &&
          (progressIndicator ||
            React.Children.map(
              children,
              child =>
                isIndicator(child) &&
                React.cloneElement(child, {
                  value: child.props.value || value,
                  standalone: child.props.standalone || page,
                }),
            ))}
        {message ||
          (!!content.length && (
            <ProgressMessage id={this.messageId}>
              {loading && content}
            </ProgressMessage>
          ))}
      </Element>
    )
  }
}

ProgressIndicator.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  /** Can supply a render function to have more control over output */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  id: PropTypes.string,
  className: PropTypes.string,
  /** Percentage value of progress if determinate */
  value: PropTypes.number,
  /** Triggers overlay styling for page level loading */
  page: PropTypes.bool,
  /** Loading message for use with render function, will have relevant props applied */
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /** Indicator for use with render function, will have relevant props applied */
  indicator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /** Whether the indicator is loading or not */
  loading: PropTypes.bool,
  /** Prevents automatically applying focus on the progress element, note this can cause a worse experience for screen reader users */
  preventFocus: PropTypes.bool,
}

ProgressIndicator.defaultProps = {
  element: 'div',
  children: undefined,
  id: undefined,
  className: undefined,
  value: undefined,
  page: false,
  message: undefined,
  indicator: undefined,
  loading: false,
  preventFocus: false,
}

ProgressIndicator.displayName = 'ProgressIndicator'

export default ProgressIndicator
