import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { filterProps } from '@jsluna/utils'

import Input from './Input'

const baseClassName = 'ln-c-form-option'

class FormOption extends Component {
  constructor(props) {
    super(props)
    this.inputRef = null

    this.clickHander = this.clickHander.bind(this)
  }

  clickHander(e) {
    const { focusOnClick, onClick } = this.props
    if (focusOnClick && this.inputRef) {
      this.inputRef.focus()
    }

    if (onClick) {
      onClick(e)
    }
  }

  render() {
    const {
      element,
      label,
      hideLabel,
      className,
      fullWidth,
      inline,
      type,
      outlined,
      name,
      focusOnClick,
      ...rest
    } = this.props

    const Element = element
    // eslint-disable-next-line react/destructuring-assignment
    const id = this.props.value ? `${name}-${this.props.value}` : name

    return (
      <Element
        className={classnames(
          baseClassName,
          `${baseClassName}--${type}`,
          fullWidth && `${baseClassName}--full`,
          inline && `${baseClassName}--inline`,
          hideLabel && `${baseClassName}--hide-label`,
          outlined && `${baseClassName}--outlined`,
          className,
        )}
      >
        <Input
          {...filterProps(rest, ['focusOnClick', 'onClick'])}
          name={name}
          type={type === 'switch' ? 'checkbox' : type}
          className={`${baseClassName}__input`}
          id={id}
          innerRef={el => {
            this.inputRef = el
          }}
          onClick={this.clickHander}
        />
        <label className={`${baseClassName}__label`} htmlFor={id}>
          <span>{label}</span>
        </label>
      </Element>
    )
  }
}

FormOption.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  /** The name that gets submitted to the server */
  name: PropTypes.string.isRequired,
  /** What format the option should take */
  type: PropTypes.oneOf(['checkbox', 'radio', 'switch']),
  /** Text describing the input */
  label: PropTypes.node,
  /** Makes the label only visible to screen readers */
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  /** Change to block level display */
  fullWidth: PropTypes.bool,
  /** Allows option to be used as an inline element */
  inline: PropTypes.bool,
  /** Value of the input, also used to provide a unique id */
  value: PropTypes.string,
  /** Whether the option has a border */
  outlined: PropTypes.bool,
  /** Apply focus when option is clicked, to give consistent focus behaviour on MacOS */
  focusOnClick: PropTypes.bool,
  onClick: PropTypes.func,
}

FormOption.defaultProps = {
  element: 'div',
  type: 'radio',
  label: undefined,
  hideLabel: false,
  className: undefined,
  fullWidth: false,
  inline: false,
  value: undefined,
  outlined: false,
  focusOnClick: false,
  onClick: undefined,
}

FormOption.displayName = 'FormOption'

export default FormOption
