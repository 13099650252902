import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FlagBody = ({ element, className, children, alignment, ...rest }) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames(className, 'ln-o-flag__body', {
        [`ln-o-flag__body--${alignment}`]: alignment,
      })}
    >
      {children}
    </Element>
  )
}

FlagBody.displayName = 'FlagBody'

FlagBody.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  alignment: PropTypes.oneOf(['top', 'bottom']),
}

FlagBody.defaultProps = {
  element: 'div',
  className: undefined,
  alignment: undefined,
}

export default FlagBody
