import React from 'react'
import PropTypes from 'prop-types'

import { Flag } from '../flag'

import LoadingIcon from './LoadingIcon'

/**
 * @deprecated Use ProgressIndicator
 */
const LoadingIndicator = ({ message, className, inverted, ...rest }) => (
  <Flag.Wrapper {...rest} className={className}>
    <Flag.Component>
      <LoadingIcon inverted={inverted} />
    </Flag.Component>
    <Flag.Body className="ln-u-margin-left*2">{message}</Flag.Body>
  </Flag.Wrapper>
)

LoadingIndicator.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  inverted: PropTypes.bool,
}

LoadingIndicator.defaultProps = {
  message: 'Please wait, loading...',
  className: undefined,
  inverted: false,
}

LoadingIndicator.displayName = 'LoadingIndicator'

export default LoadingIndicator
