import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { VARIANT } from './constants'

/**
 * @see Extends [`Button`](#/Components/Button?id=button-1) by setting relevant `variant`
 */
const FilledButton = props => (
  <Button {...props} variant={VARIANT.FILLED}>
    {props.children}
  </Button>
)

FilledButton.propTypes = {
  children: PropTypes.node.isRequired,
}

FilledButton.displayName = 'FilledButton'

export default FilledButton
