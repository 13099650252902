import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '@jsluna/card'

const Fieldset = ({ children, className, ...rest }) => (
  <Card {...rest} className={className}>
    <fieldset>{children}</fieldset>
  </Card>
)

Fieldset.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

Fieldset.defaultProps = {
  children: undefined,
  className: undefined,
}

Fieldset.displayName = 'Fieldset'

export default Fieldset
