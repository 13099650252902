import React from 'react'
import PropTypes from 'prop-types'

const PageBody = ({ element, children }) => {
  const Element = element

  return <Element className="ln-o-page__body">{children}</Element>
}

PageBody.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
}

PageBody.defaultProps = {
  element: 'div',
  children: undefined,
}

PageBody.displayName = 'PageBody'

export default PageBody
