import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const HeaderBody = ({
  element,
  children,
  id,
  className,
  flushTop,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      id={id}
      className={classnames(
        className,
        'ln-c-header-body',
        flushTop && 'ln-u-flush-top',
      )}
    >
      {children}
    </Element>
  )
}

HeaderBody.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  flushTop: PropTypes.bool,
}

HeaderBody.defaultProps = {
  element: 'div',
  children: undefined,
  id: 'main-content',
  className: undefined,
  flushTop: false,
}

HeaderBody.displayName = 'HeaderBody'

export default HeaderBody
