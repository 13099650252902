import NavigationWrapper from './NavigationWrapper'
import NavigationList from './Navigation'
import MenuButton from './MenuButton'
import NavigationDropdown, {
  NavigationDropdownComponent,
} from './NavigationDropdown'

// Deprecated
const Navigation = {
  Wrapper: NavigationWrapper,
  List: NavigationList,
}

export {
  Navigation,
  NavigationWrapper,
  NavigationList,
  MenuButton,
  NavigationDropdown,
  NavigationDropdownComponent,
}
