import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/**
 * @deprecated Use ProgressSpinner
 */
const LoadingIcon = ({ className, inverted, ...rest }) => (
  <div
    {...rest}
    className={classnames(
      className,
      'ln-c-loading-indicator',
      inverted && 'ln-c-loading-indicator--inverted',
    )}
  >
    <div className="ln-c-loading-indicator__bar" />
  </div>
)

LoadingIcon.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
}

LoadingIcon.defaultProps = {
  className: undefined,
  inverted: false,
}

LoadingIcon.displayName = 'LoadingIcon'

export default LoadingIcon
