import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Container = ({
  element,
  className,
  children,
  fluid,
  free,
  size,
  soft,
  clearfix,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames(
        'ln-o-container',
        {
          [`ln-o-container--${size}`]: size,
          'ln-o-container--soft': typeof soft === 'boolean' && soft,
          [`ln-o-container--soft-${soft}`]: typeof soft === 'string',
          'ln-o-container--fluid': fluid,
          'ln-o-container--free': free,
          'ln-o-clearfix': clearfix,
        },
        className,
      )}
    >
      {children}
    </Element>
  )
}

Container.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  soft: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fluid: PropTypes.bool,
  free: PropTypes.bool,
  clearfix: PropTypes.bool,
}

Container.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  size: undefined,
  soft: undefined,
  fluid: false,
  free: false,
  clearfix: false,
}

Container.displayName = 'Container'

export default Container
