import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { COLOR, VARIANT } from './constants'

const baseClassName = 'ln-c-button'

/**
 * Base component extended by all other button variants
 */
const Button = ({
  element,
  containerElement,
  children,
  className,
  disabled,
  fullWidth,
  onClick,
  type,
  innerRef,
  buttonRef,
  circle,
  hard,
  beta,
  light,
  dark,
  role,
  variant,
  color,
  ...rest
}) => {
  const buttonEl = containerElement || element

  let props = {
    ...rest,
    className: classnames(
      baseClassName,
      variant && `${baseClassName}--${variant}`,
      fullWidth && `${baseClassName}--full`,
      circle && `${baseClassName}--circle`,
      hard && `${baseClassName}--hard`,
      color && `${baseClassName}--${color}`,
      beta && `${baseClassName}--${COLOR.BETA}`,
      dark && `${baseClassName}--${COLOR.DARK}`,
      light && `${baseClassName}--${COLOR.LIGHT}`,
      disabled && 'is-disabled',
      className,
    ),
    'aria-disabled': disabled || undefined,
    role,
    onClick,
    ref: innerRef || buttonRef,
  }

  if (buttonEl === 'button') {
    props = {
      ...props,
      role: role === 'button' ? undefined : role,
      disabled,
      type,
    }
  }

  return React.isValidElement(buttonEl)
    ? React.cloneElement(buttonEl, props, children)
    : React.createElement(buttonEl, props, children)
}

Button.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /** @deprecated Use `element` */
  containerElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * Use the beta colour theme for use on dark backgrounds
   * @deprecated use `color`
   */
  beta: PropTypes.bool,
  /** @deprecated Use `innerRef` */
  buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node.isRequired,
  /** Resets padding and adds border radius to be circular in shape */
  circle: PropTypes.bool,
  className: PropTypes.string,
  /** Colour theme to be used */
  color: PropTypes.oneOf(Object.keys(COLOR).map(key => COLOR[key])),
  /**
   * Use the dark colour theme for use on light backgrounds
   * @deprecated use `color`
   */
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Block level display to take up full width of container */
  fullWidth: PropTypes.bool,
  /** Removes padding from the ends of the button */
  hard: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * Use the light colour theme for use on dark backgrounds
   * @deprecated use `color`
   */
  light: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit']),
  /** Determines the display style used e.g. filled / outlined  */
  variant: PropTypes.oneOf(Object.keys(VARIANT).map(key => VARIANT[key])),
}

Button.defaultProps = {
  beta: false,
  buttonRef: undefined,
  circle: false,
  className: undefined,
  containerElement: undefined,
  dark: false,
  disabled: false,
  element: 'button',
  fullWidth: false,
  innerRef: undefined,
  light: false,
  role: 'button',
  hard: false,
  type: 'button',
  variant: undefined,
  color: undefined,
}

Button.displayName = 'Button'

export default Button
