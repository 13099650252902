import React from 'react'
import PropTypes from 'prop-types'

import { Search } from 'luna-icons'

import IconButton from './IconButton'
import { VARIANT } from './constants'

/**
 * Helper button to be used in combination with form inputs that makes use of the
 * [`Search`](#/Icons) icon
 * @see Extends [`Button`](#/Components/Button?id=button-1)
 */
const SearchButton = ({ icon, text, inputAction, label, ...rest }) => (
  <IconButton
    variant={!inputAction ? VARIANT.FILLED : undefined}
    type="submit"
    label={text || label}
    hideLabel
    {...rest}
  >
    {icon}
  </IconButton>
)

SearchButton.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  inputAction: PropTypes.bool,
  label: PropTypes.string,
}

SearchButton.defaultProps = {
  /** Icon to be displayed */
  icon: <Search />,
  /** @deprecated Use `label` */
  text: undefined,
  /** Applies the bare button styling */
  inputAction: false,
  /** Text description for screen readers */
  label: 'Search',
}

SearchButton.displayName = 'SearchButton'

export default SearchButton
