import React, { Component } from 'react'
import PropTypes from 'prop-types'

import filterProps from '../../utils/filterProps'

import TextInput from './TextInput'

class NumberInput extends Component {
  constructor(props) {
    super(props)

    const { defaultValue } = props

    this.handleChange = this.handleChange.bind(this)
    this.state = {
      value: defaultValue,
    }
  }

  handleChange(e) {
    const { inputFilter, onChange } = this.props
    const value = e.target.value.replace(inputFilter, '')
    this.setState({ value })

    if (onChange) {
      onChange(e, value)
    }
  }

  render() {
    const { increment, pattern, ...rest } = this.props
    return (
      <TextInput
        {...filterProps(rest, ['inputFilter', 'onChange', 'defaultValue'])}
        value={this.state.value}
        onChange={this.handleChange}
        type={increment ? 'number' : undefined}
        inputMode="decimal"
        pattern={pattern}
      />
    )
  }
}

NumberInput.propTypes = {
  /** Enables use of methods to filter out specific inputs */
  onChange: PropTypes.func,
  /** Add browsers stepper control */
  increment: PropTypes.bool,
  /** Override filter used to restrict non numeric characters */
  inputFilter: PropTypes.instanceOf(RegExp),
  /** Browser validation regex, defaulted to trigger number keyboard in iOS */
  pattern: PropTypes.string,
  defaultValue: PropTypes.string,
}

NumberInput.defaultProps = {
  increment: false,
  onChange: undefined,
  inputFilter: /[^0-9.,]/g,
  pattern: '[0-9]*',
  defaultValue: '',
}

NumberInput.displayName = 'NumberInput'

export default NumberInput
