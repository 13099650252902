import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableContainer = ({
  className,
  responsive,
  labels,
  sortable,
  fixed,
  children,
  ...rest
}) => (
  <div {...rest} className="ln-c-table-container">
    <table
      className={classnames(className, 'ln-c-table', {
        [`ln-c-table--responsive@${responsive}`]:
          typeof responsive === 'string',
        'ln-c-table--responsive@md':
          typeof responsive !== 'string' && responsive,
        'ln-c-table--no-labels': !labels,
        'ln-c-table--sorted': sortable,
        'ln-c-table--fixed': fixed,
      })}
    >
      {children}
    </table>
  </div>
)

TableContainer.propTypes = {
  className: PropTypes.string,
  responsive: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  labels: PropTypes.bool,
  sortable: PropTypes.bool,
  fixed: PropTypes.bool,
  children: PropTypes.node,
}

TableContainer.defaultProps = {
  className: undefined,
  responsive: false,
  labels: true,
  sortable: false,
  fixed: false,
  children: undefined,
}

TableContainer.displayName = 'TableContainer'

export default TableContainer
