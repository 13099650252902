import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ListGroup = ({
  element,
  children,
  className,
  actionable,
  type,
  color,
  inline,
  size,
  spaced,
  full,
  light,
  dark,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-list-group',
        actionable && 'ln-c-list-group--actionable',
        type && `ln-c-list-group--${type}`,
        color && `ln-c-list-group--${color}`,
        size && `ln-c-list-group--${size}`,
        inline &&
          (typeof inline === 'string'
            ? `ln-c-list-group--inline@${inline}`
            : 'ln-c-list-group--inline'),
        spaced && 'ln-c-list-group--spaced',
        full && 'ln-c-list-group--full',
        light && 'ln-c-list-group--light',
        dark && 'ln-c-list-group--dark',
        className,
      )}
    >
      {children}
    </Element>
  )
}

ListGroup.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Items within the group should have hover states */
  actionable: PropTypes.bool,
  /** Change the list item style to menu or pill navigation variants */
  type: PropTypes.oneOf(['full', 'pill']),
  /** Change colour theme used */
  color: PropTypes.oneOf(['light', 'dark']),
  /** Display items in a row, can provide a breakpoint reference for responsive behaviour */
  inline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.oneOf(['lg', 'sm']),
  spaced: PropTypes.bool,
  /** @deprecated Use `type` */
  full: PropTypes.bool,
  /** @deprecated Use `color` */
  light: PropTypes.bool,
  /** @deprecated Use `color` */
  dark: PropTypes.bool,
}

ListGroup.defaultProps = {
  /** Allows the top-level element to be customized */
  element: 'ul',
  children: undefined,
  className: undefined,
  actionable: false,
  type: undefined,
  color: undefined,
  inline: false,
  size: undefined,
  spaced: false,
  full: false,
  light: false,
  dark: false,
}

ListGroup.displayName = 'ListGroup'

export default ListGroup
