const getAnimateDistance = (selectedPageIndex, remainingTabs, tabsPerPage) => {
  if (selectedPageIndex === undefined) {
    return 0
  }

  let animateDistance = selectedPageIndex * -100

  if (remainingTabs < 0) {
    animateDistance += remainingTabs * -1 * (100 / tabsPerPage)
  }

  return animateDistance
}

export default getAnimateDistance
