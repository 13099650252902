import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Input from './Input'

const FormOption = ({
  element,
  label,
  hideLabel,
  className,
  fullWidth,
  inline,
  type,
  outlined,
  ...props
}) => {
  const Element = element
  const id = props.value ? `${props.name}-${props.value}` : props.name

  return (
    <Element
      className={classnames(
        'ln-c-form-option',
        `ln-c-form-option--${type}`,
        fullWidth && 'ln-c-form-option--full',
        inline && 'ln-c-form-option--inline',
        hideLabel && 'ln-c-form-option--hide-label',
        outlined && 'ln-c-form-option--outlined',
        className,
      )}
    >
      <Input
        type={type === 'switch' ? 'checkbox' : type}
        className="ln-c-form-option__input"
        {...props}
        id={id}
      />
      <label className="ln-c-form-option__label" htmlFor={id}>
        <span>{label}</span>
      </label>
    </Element>
  )
}

FormOption.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  /** The name that gets submitted to the server */
  name: PropTypes.string.isRequired,
  /** What format the option should take */
  type: PropTypes.oneOf(['checkbox', 'radio', 'switch']),
  /** Text describing the input */
  label: PropTypes.node,
  /** Makes the label only visible to screen readers */
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  /** Change to block level display */
  fullWidth: PropTypes.bool,
  /** Allows option to be used as an inline element */
  inline: PropTypes.bool,
  /** Value of the input, also used to provide a unique id */
  value: PropTypes.string,
  /** Whether the option has a border */
  outlined: PropTypes.bool,
}

FormOption.defaultProps = {
  element: 'div',
  type: 'radio',
  label: undefined,
  hideLabel: false,
  className: undefined,
  fullWidth: false,
  inline: false,
  value: undefined,
  outlined: false,
}

FormOption.displayName = 'FormOption'

export default FormOption
