import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const DEFAULT_PLACEHOLDER = 'Please select'

const Select = ({
  name,
  options,
  required,
  disabled,
  onBlur,
  onChange,
  onFocus,
  value,
  className,
  placeholder,
  defaultValue,
  readOnly,
  tabIndex,
  invalid,
  innerRef,
  ...rest
}) => (
  <select
    {...rest}
    id={name}
    name={name}
    className={classnames('ln-c-select', className)}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    value={value}
    defaultValue={defaultValue}
    required={required}
    tabIndex={tabIndex}
    disabled={disabled}
    readOnly={readOnly}
    ref={innerRef}
    aria-disabled={disabled || undefined}
    aria-required={required === false ? required : undefined}
    aria-invalid={invalid || undefined}
  >
    {placeholder && (
      <option value="">
        {typeof placeholder === 'string' ? placeholder : DEFAULT_PLACEHOLDER}
      </option>
    )}
    {options.map(option => {
      const {
        label,
        value: optionValue,
        disabled: optionDisabled,
        ...optionRest
      } = option

      return (
        <option
          {...optionRest}
          key={`${name}-${option.value}`}
          value={optionValue}
          disabled={!!optionDisabled}
          aria-disabled={!!optionDisabled || undefined}
        >
          {label}
        </option>
      )
    })}
  </select>
)

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  tabIndex: PropTypes.number,
  invalid: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

Select.defaultProps = {
  disabled: false,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  value: undefined,
  defaultValue: undefined,
  placeholder: DEFAULT_PLACEHOLDER,
  className: undefined,
  required: undefined,
  readOnly: false,
  tabIndex: undefined,
  invalid: undefined,
  innerRef: undefined,
}

Select.displayName = 'Select'

export default Select
