import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FlagWrapper = ({ element, className, children, respondAt, ...rest }) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames(
        className,
        !respondAt && 'ln-o-flag',
        respondAt && `ln-o-flag@${respondAt}`,
      )}
    >
      {children}
    </Element>
  )
}

FlagWrapper.displayName = 'FlagWrapper'

FlagWrapper.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  respondAt: PropTypes.string,
}

FlagWrapper.defaultProps = {
  element: 'div',
  className: undefined,
  respondAt: undefined,
}

export default FlagWrapper
