import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const PaginationNav = ({
  className,
  children,
  label,
  showFirstAndLast,
  showPages,
  fullWidth,
}) => (
  <nav
    className={classnames(
      className,
      'ln-c-pagination',
      showFirstAndLast && 'ln-c-pagination--first-last',
      showPages && 'ln-c-pagination--pages',
      fullWidth && 'ln-c-pagination--full',
    )}
    aria-label={label}
  >
    <ul className="ln-c-pagination__list">{children}</ul>
  </nav>
)

PaginationNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  showFirstAndLast: PropTypes.bool,
  showPages: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

PaginationNav.defaultProps = {
  className: undefined,
  children: undefined,
  label: 'Page navigation',
  showFirstAndLast: false,
  showPages: false,
  fullWidth: false,
}

PaginationNav.displayName = 'PaginationNav'

export default PaginationNav
