import React from 'react'
import PropTypes from 'prop-types'

const Form = ({
  children,
  className,
  onSubmit,
  useBrowserValidation,
  ...rest
}) => (
  <form
    {...rest}
    noValidate={!useBrowserValidation}
    onSubmit={onSubmit}
    className={className}
  >
    {children}
  </form>
)

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  useBrowserValidation: PropTypes.bool,
}

Form.defaultProps = {
  className: undefined,
  onSubmit: undefined,
  useBrowserValidation: false,
}

Form.displayName = 'Form'

export default Form
