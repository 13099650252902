import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import debounce from '../../utils/debounce'
import filterProps from '../../utils/filterProps'

const DEBOUNCE_WAIT = 100

class Page extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSticky: false,
    }

    this.handleScroll = this.handleScroll.bind(this)

    this.debouncedHandleScroll = debounce(this.handleScroll, DEBOUNCE_WAIT)
  }

  componentDidMount() {
    const { stickyHeader } = this.props

    if (stickyHeader) {
      window.addEventListener('scroll', this.debouncedHandleScroll)
    }
  }

  componentWillUnmount() {
    const { stickyHeader } = this.props

    if (stickyHeader) {
      window.removeEventListener('scroll', this.debouncedHandleScroll)
    }
  }

  handleScroll() {
    const { isSticky: currentlySticky } = this.state
    const { stickyThreshold, stickyOffset, onHeaderSticky } = this.props
    const pageY = window.scrollY || window.pageYOffset
    let isSticky

    if (pageY > stickyThreshold && !currentlySticky) {
      isSticky = true
    } else if (pageY <= stickyThreshold - stickyOffset) {
      isSticky = false
    }

    if (typeof isSticky !== 'undefined') {
      this.setState({
        isSticky,
      })

      if (onHeaderSticky) {
        onHeaderSticky(isSticky)
      }
    }
  }

  render() {
    const { isSticky } = this.state
    const {
      element,
      children,
      className,
      stickyHeader,
      customHeader,
      ...rest
    } = this.props
    const Element = element

    return (
      <Element
        {...filterProps(rest, [
          'stickyThreshold',
          'stickyOffset',
          'onHeaderSticky',
        ])}
        className={classnames(
          'ln-o-page',
          customHeader && 'ln-o-page--custom-header',
          stickyHeader && 'ln-o-page--sticky-header',
          stickyHeader && isSticky && 'ln-o-page--is-sticky',
          className,
        )}
      >
        {children}
      </Element>
    )
  }
}

Page.propTypes = {
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  customHeader: PropTypes.bool,
  /** If the layout should fix the header to the top of the window */
  stickyHeader: PropTypes.bool,
  /** When sticky modifier class should apply, defaults to initial value of $ln-header-height + $ln-space-px */
  stickyThreshold: PropTypes.number,
  /**
   * Modifier to change when sticky class should be removed to avoid rubber band effect,
   * set to height of element being removed on sticky. Defaults to stickyThreshold / 2.
   */
  stickyOffset: PropTypes.number,
  /** Event callback when header changes from fixed to static */
  onHeaderSticky: PropTypes.func,
}

Page.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  customHeader: false,
  stickyHeader: false,
  stickyThreshold: 72,
  stickyOffset: 36,
  onHeaderSticky: undefined,
}

Page.displayName = 'Page'

export default Page
