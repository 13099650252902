import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableCell = ({
  children,
  label,
  className,
  align,
  lastWhenStacked,
  ...rest
}) => (
  <td
    {...rest}
    className={classnames(
      className,
      'ln-c-table__cell',
      {
        [`ln-c-table__cell--text-align-${align}`]: align,
      },
      lastWhenStacked && 'ln-c-table__cell--last-when-stacked',
    )}
    data-label={label}
  >
    {children}
  </td>
)

TableCell.propTypes = {
  /** Content to render within the cell */
  children: PropTypes.node,
  /** Label associated with the cell - used when displayed in a responsive "stacked" view */
  label: PropTypes.string,
  /** Add custom classes e.g. for styling purposes */
  className: PropTypes.string,
  /** Horizontally aligns the table cell contents */
  align: PropTypes.oneOf(['left', 'right', 'center']),
  /** Displays at the bottom in responsive mode when "stack" view showing (e.g. on mobile) */
  lastWhenStacked: PropTypes.bool,
}

TableCell.defaultProps = {
  children: undefined,
  label: undefined,
  className: undefined,
  align: undefined,
  lastWhenStacked: false,
}

TableCell.displayName = 'TableCell'

export default TableCell
