import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const InputControl = ({ children }) => {
  const element = React.Children.only(children)

  return React.cloneElement(element, {
    className: classnames(
      'ln-c-input-group__control',
      element.props && element.props.className,
    ),
  })
}

InputControl.propTypes = {
  children: PropTypes.node,
}

InputControl.defaultProps = {
  children: undefined,
}

InputControl.displayName = 'InputControl'

export default InputControl
