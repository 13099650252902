import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose, withState, withHandlers, setDisplayName } from 'recompose'

import { Form } from '../form'
import filterProps from '../../utils/filterProps'
import { toggleHandler, changeHandler, submitHandler } from './utils'

const Search = ({
  className,
  placeholder,
  handleSubmit,
  expandable,
  value,
  handleChange,
  open,
  toggle,
  onBlur,
  onFocus,
  allowEmptySubmit,
  ...rest
}) => (
  <Form
    {...filterProps(rest, ['changeValue', 'onExpandClick'])}
    onSubmit={handleSubmit}
    className={classnames('ln-c-search', {
      'ln-c-search--expandable': expandable,
      'is-open': open,
      [className]: typeof className === 'string',
      [className.form]: !!className.form,
    })}
  >
    <input
      type="search"
      className={classnames('ln-c-search__input', 'ln-c-text-input', {
        [className.input]: !!className.input,
      })}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
    <button
      type="submit"
      className="ln-c-search__submit"
      disabled={!allowEmptySubmit && !value}
      aria-disabled={!allowEmptySubmit && (!value || undefined)}
    >
      Submit search query
    </button>
    {expandable && (
      <button
        type="button"
        className="ln-c-search--expandable__toggle"
        onClick={toggle}
      >
        Open Search
      </button>
    )}
  </Form>
)

Search.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      form: PropTypes.string,
      input: PropTypes.string,
    }),
  ]),
  placeholder: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  expandable: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  allowEmptySubmit: PropTypes.bool,
}

Search.defaultProps = {
  className: {},
  placeholder: 'Search',
  expandable: false,
  open: false,
  toggle: undefined,
  onBlur: undefined,
  onFocus: undefined,
  allowEmptySubmit: false,
}

Search.displayName = 'Search'

export { Search as SearchComponent }
export default compose(
  setDisplayName('Search'),
  withState('value', 'changeValue', ''),
  withState('open', 'onExpandClick', false),
  withHandlers({
    toggle: toggleHandler,
    handleChange: changeHandler,
    handleSubmit: submitHandler,
  }),
)(Search)
