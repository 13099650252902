import { compose, withProps, setDisplayName } from 'recompose'

import FormOptionField from './FormOptionField'

/**
 * @component
 * @see Combines [`FormOptionField`](#/Components/Form?id=formoptionfield) and [`FormGroup`](#/Components/Form?id=formgroup-1).
 */
export default compose(
  setDisplayName('RadioButtonField'),
  withProps({ optionType: 'radio' }),
)(FormOptionField)
