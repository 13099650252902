import { compose, setDisplayName, mapProps } from 'recompose'
import classnames from 'classnames'

const addLunaClass = ({
  className,
  fixed,
  size,
  fillCurrent,
  strokeCurrent,
  ...props
}) => ({
  ...props,
  className: classnames(className, 'ln-c-icon', {
    'ln-c-icon--fixed': fixed,
    [`ln-c-icon--${size}`]: size,
    'ln-c-icon--fill-current': fillCurrent,
    'ln-c-icon--stroke-current': strokeCurrent,
  }),
})

export { addLunaClass }

export default /* istanbul ignore next */ Component =>
  /* istanbul ignore next */
  compose(
    setDisplayName(Component.displayName || Component.name || 'Icon'),
    mapProps(addLunaClass),
  )(Component)
